import React from 'react';

import { observer } from 'mobx-react-lite';

import { FormControl, Button, IconButton, Tooltip, Chip } from '@mui/material';

import { api } from '../../lib/api';
import { encodeJsonToURIComponent, useDataTable } from '../../components/common/DataTable';
import { CommonDataTable } from '../../components/CustomDataTable';
import { AdminAccountsForm } from './Form';
import { useFormStyles } from '../../hooks/useFormStyles';
import { useDataForm } from '../../components/common/DataForm';
import { FormText } from '../../components/common/FormText';
import { ExcelIcon } from '../../components/ExcelIcon';
import { authStore } from '../../stores/authStore';
import { RolesSelect } from '../../components/RolesSelect';
import { PermissionsSelect } from '../../components/PermissionsSelect';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

function FilterContent({ onFilter, initialData }) {
    const classes = useFormStyles();
    const { data, fieldProps } = useDataForm({ initialData });

    function handleFilter() {
        onFilter(data);
    }

    return (
        <div className={classes.fieldsGroup}>
            <FormText {...fieldProps('filter')} label="Поиск" />
            <RolesSelect {...fieldProps('role_id')} />
            <PermissionsSelect {...fieldProps('permission_id')} />
            <FormControl>
                <Button onClick={handleFilter}>Показать</Button>
            </FormControl>
        </div>
    );
}

async function rowsLoader(params) {
    return (await api.adminAccountsList({ ...params }))?.rows;
}

async function rowsDeleter(ids) {
    return await api.adminAccountsDelete(ids);
}

export const AdminAccountsList = observer(function () {
    const hasEditPermissions = authStore.has('accounts.edit');

    const columns = [
        { field: 'login', title: 'Логин' },
        { field: 'name', title: 'Имя' },
        { field: 'email', title: 'E-mail' },
        { field: 'is_disabled', title: 'Заблокирован?', type: 'boolean' },
        {
            field: 'roles',
            title: 'Роли',
            sortable: false,
            renderValue: ({ value }) =>
                value.map((v, i) => (
                    <Chip
                        key={i}
                        label={v.name}
                        size="small"
                        variant="outlined"
                        sx={{ height: 'auto', fontSize: '0.7rem', m: '1px' }}
                    />
                )),
        },
        { field: 'created_at', type: 'datetime', title: 'Дата создания' },
        { field: 'auth_at', type: 'datetime', title: 'Дата входа' },
    ];

    const [editId, setEditId] = React.useState(false);

    const { tableProps, tableActions } = useDataTable({ columns, rowsLoader, rowsDeleter, filterData: { filter: '' } });

    function handleFilterChange(value) {
        tableActions.applyFilterData(value);
        tableActions.setFilterOpen(false);
    }

    function handleTry(accountId) {
        api.adminAccountsTry(accountId).then((data) => {
            if (data) {
                authStore.setAuthorized(data);
            }
        });
    }

    const filterDataJson = encodeJsonToURIComponent(tableProps.filterData);

    return (
        <React.Fragment>
            {false !== editId && (
                <AdminAccountsForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CommonDataTable
                {...tableProps}
                filterContent={<FilterContent onFilter={handleFilterChange} initialData={tableProps.filterData} />}
                onCreate={hasEditPermissions ? () => setEditId(null) : undefined}
                onEdit={hasEditPermissions ? (id) => setEditId(id) : undefined}
                disableSelection={!hasEditPermissions}
                toolbarButtons={
                    <Tooltip title="Скачать в Excel">
                        <IconButton
                            component="a"
                            href={'/api/admin/accounts/download?filterData=' + filterDataJson}
                            download
                            color="excel"
                            disabled={tableProps.loading}
                            sx={{ ml: 1 }}
                        >
                            <ExcelIcon />
                        </IconButton>
                    </Tooltip>
                }
                renderRowButtons={
                    hasEditPermissions
                        ? ({ rowId }) => (
                              <Tooltip title="Войти под этим аккаунтом">
                                  <IconButton onClick={() => handleTry(rowId)}>
                                      <PlayArrowIcon />
                                  </IconButton>
                              </Tooltip>
                          )
                        : undefined
                }
            />
        </React.Fragment>
    );
});
