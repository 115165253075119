import React from 'react';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { FormText } from '../../components/common/FormText';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { Validator } from '../../lib/utils';
import { useFormStyles } from '../../hooks/useFormStyles';

function UrlMapsForm({ onClose = () => null, onSave = () => null }) {
    const classes = useFormStyles();
    const isMounted = useMountedState();

    const { data, setErrors, setProcessing, fieldProps, formProps } = useDataForm();

    function handleSave() {
        const validator = new Validator(data).checkAll([{ urls: 'required' }]);

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);

            api.urlMapsCreate(data)
                .then((result) => {
                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                            } else {
                                onSave(result);
                            }
                        }
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                });
        }
    }

    return (
        <DataForm {...formProps()} onSave={handleSave} onClose={onClose} closeButtonText="Закрыть">
            <div className={classes.fieldsGroup}>
                <FormText
                    multiline
                    {...fieldProps('urls')}
                    label="Список URL"
                    autoFocus
                    required
                    fullWidth
                    helperText="Каждая URL на новой строке"
                />
            </div>
        </DataForm>
    );
}

export { UrlMapsForm };
