import React from 'react';

import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { Button, Link, useTheme, FormControl, IconButton } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { api } from '../../lib/api';
import { useDataTable } from '../../components/common/DataTable';
import { CommonDataTable } from '../../components/CustomDataTable';
import { PlansForm } from './Form';
import { humanFileSize } from '../../lib/utils';
import { useDataForm } from '../../components/common/DataForm';
import { FormDate } from '../../components/common/FormDate';
import { ClientsSelect } from '../../components/ClientsSelect';
import { BrandsSelect } from '../../components/BrandsSelect';
import { CampaignsSelect } from '../../components/CampaignsSelect';
import { useFormStyles } from '../../hooks/useFormStyles';
import { authStore } from '../../stores/authStore';
import { InstrumentsSelect } from '../../components/InstrumentsSelect';
import { FormText } from '../../components/common/FormText';

function FilterContent({ onFilter, initialData = {} }) {
    const classes = useFormStyles();
    const { data, fieldProps } = useDataForm({ initialData });

    function handleFilter() {
        onFilter(data);
    }

    return (
        <div>
            <div className={classes.fieldsGroup}>
                <ClientsSelect {...fieldProps('client_id')} />
                <BrandsSelect {...fieldProps('brand_id')} clientIds={data?.client_id} />
                <CampaignsSelect {...fieldProps('campaign_id')} brandIds={data?.brand_id} />
                <InstrumentsSelect {...fieldProps('plan_campaigns.instrument_id')} />
                <FormText {...fieldProps('plan_campaigns.iid')} label="iid" />
            </div>
            <div className={classes.fieldsGroup}>
                <FormDate {...fieldProps('campaign_start_on')} label="Начало кампании" />
                <FormDate {...fieldProps('campaign_finish_on')} label="Окончание кампании" />
                <FormControl>
                    <Button onClick={handleFilter}>Показать</Button>
                </FormControl>
            </div>
        </div>
    );
}

async function rowsLoader(params) {
    return (await api.plansList({ ...params }))?.rows;
}

async function rowsDeleter(ids) {
    return await api.plansDelete(ids);
}

export const PlansList = observer(function () {
    const hasEditPermissions = authStore.has('plans.edit');

    const theme = useTheme();
    const [editId, setEditId] = React.useState(false);
    const navigate = useNavigate();

    const columns = [
        { field: 'campaign_start_on', title: 'Старт', type: 'date' },
        { field: 'campaign_finish_on', title: 'Завершение', type: 'date' },
        { field: 'client_name', title: 'Клиент' },
        { field: 'campaign_name', title: 'Кампания' },
        { field: 'brand_name', title: 'Бренд' },
        {
            field: 'original_file_name',
            title: 'Исходный файл',
            renderValue: ({ row }) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AttachmentIcon style={{ marginRight: theme.spacing(1) }} />
                        <Link download href={row.original_file_url}>
                            ({humanFileSize(row.original_file_size)})
                        </Link>
                    </div>
                );
            },
        },
        {
            field: 'processed_file_name',
            title: 'Обработанный файл',
            renderValue: ({ row }) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AttachmentIcon style={{ marginRight: theme.spacing(1) }} color="secondary" />
                        <Link download href={row.processed_file_url}>
                            ({humanFileSize(row.processed_file_size)})
                        </Link>
                    </div>
                );
            },
        },
        { field: 'created_at', title: 'Дата загрузки', type: 'datetime' },
        { field: 'created_by_name', title: 'Кто загрузил' },
    ];

    const { tableProps, tableActions } = useDataTable({ columns, rowsLoader, rowsDeleter });

    function handleOnFilter(filterData) {
        tableActions.applyFilterData(filterData);
        tableActions.setFilterOpen(false);
    }

    function showReport(id) {
        navigate('/plans/reports/' + encodeURIComponent(id));
    }

    function renderRowButtons({ row }) {
        return (
            <IconButton onClick={() => showReport(row.id)}>
                <AssessmentIcon />
            </IconButton>
        );
    }

    return (
        <React.Fragment>
            {false !== editId && (
                <PlansForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CommonDataTable
                {...tableProps}
                filterContent={<FilterContent onFilter={handleOnFilter} initialData={tableProps.filterData} />}
                onCreate={hasEditPermissions ? () => setEditId(null) : undefined}
                createButtonText="Загрузить план"
                renderRowButtons={renderRowButtons}
                disableSelection={!hasEditPermissions}
            />
        </React.Fragment>
    );
});
