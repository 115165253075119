import React from 'react';

import { Box, CircularProgress } from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { CommonDataTable } from '../../components/CustomDataTable';
import { api } from '../../lib/api';

export function PairsPairsList({ pairId }) {
    const [loading, setLoading] = React.useState(true);

    const columns = [
        { field: 'full_name1', title: 'Имя' },
        { field: 'department1', title: 'Отдел' },
        { field: 'birthday1', title: 'День рождения', type: 'date' },
        { title: '', renderValue: () => <SyncAltIcon fontSize="large" /> },
        { field: 'full_name2', title: 'Имя' },
        { field: 'department2', title: 'Отдел' },
        { field: 'birthday2', title: 'День рождения', type: 'date' },
    ];

    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        let discard = false;
        setLoading(true);

        api.pairsPairsList(pairId).then((data) => {
            if (!discard) {
                setLoading(false);
            }

            if (!discard && data) {
                setRows(data.rows);
            }
        });

        return () => (discard = true);
    }, [pairId]);

    if (loading) {
        return (
            <Box p={2}>
                <CircularProgress />
            </Box>
        );
    }

    return <CommonDataTable columns={columns} rows={rows} inlineTable maximizeWidth={false} />;
}
