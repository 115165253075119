import React from 'react';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { messagesStore } from '../../stores/messagesStore';
import { FormText } from '../../components/common/FormText';
import { PermissionsSelect } from '../../components/PermissionsSelect';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { Validator } from '../../lib/utils';
import { useFormStyles } from '../../hooks/useFormStyles';

export function AdminRolesForm({ objectId, onClose = () => null, onSave = () => null }) {
    const classes = useFormStyles();
    const isMounted = useMountedState();

    const { data, setData, setErrors, setLoading, setProcessing, fieldProps, formProps } = useDataForm();

    function handleOpen() {
        if (objectId) {
            setLoading(true);
            api.adminRolesGet(objectId)
                .then((data) => {
                    if (data && isMounted()) {
                        data.data.permissions = data.data.permissions.map((i) => i.id);
                        setData(data.data);
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setLoading(false);
                    }
                });
        }
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([{ name: 'required' }, { permissions: 'required' }]);
        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);

            (objectId ? api.adminRolesEdit(objectId, data) : api.adminRolesCreate(data))
                .then((result) => {
                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                            } else {
                                messagesStore.setSuccessSaved(true);
                                onSave();
                            }
                        }
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                });
        }
    }

    return (
        <DataForm {...formProps()} editing={!!objectId} onOpen={handleOpen} onSave={handleSave} onClose={onClose}>
            <div className={classes.fieldsGroup}>
                <FormText fullWidth {...fieldProps('name')} label="Название" autoFocus required />
            </div>
            <div className={classes.fieldsGroup}>
                <PermissionsSelect fullWidth {...fieldProps('permissions')} required />
            </div>
        </DataForm>
    );
}
