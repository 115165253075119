import React from 'react';

import { makeStyles } from '@mui/styles';
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';

import similarweb from '../../pics/apps/similarweb.jpg';
import snowflake from '../../pics/apps/snowflake.jpg';
import facts from '../../pics/apps/facts.jpg';
import optimize from '../../pics/apps/optimize.jpg';
import screenshoter from '../../pics/apps/screenshoter.jpg';
import pairs from '../../pics/apps/pairs.jpg';

const useClasses = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    project: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flexGrow: 1,
        '&:hover': {
            boxShadow: theme.shadows[7],
        },
        textDecoration: 'none',
    },
}));

function ProjectCard({ title, background, children, ...other }) {
    const classes = useClasses();

    return (
        <Card component="a" className={classes.project} {...other}>
            <CardHeader title={title} />
            <CardMedia style={{ height: 80 }} image={background} />
            <CardContent style={{ maxWidth: 300 }}>{children}</CardContent>
        </Card>
    );
}

export function Welcome() {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <ProjectCard title="План-Факт" component={Link} to="/plans" background={facts}>
                Загрузка медиапланов и сбор статистики их эффективности.
            </ProjectCard>
            <ProjectCard title="Снежинка" href="https://snowflake.icom.agency" background={snowflake}>
                Собирает статистику по ключевым словам от Яндекс и Similarweb.
            </ProjectCard>
            <ProjectCard title="Оптимизатор" href="https://optimize.icom.agency" background={optimize}>
                Сервис расчёта оптимального бюджета/дохода/прибыли по рекламным каналам.
            </ProjectCard>
            <ProjectCard title="Парогенератор" component={Link} to="/pairs" background={pairs}>
                Разбивает список сотрудников на пары случайным образом, исключая повторения.
            </ProjectCard>
            <ProjectCard title="Similarweb" href="https://similarweb.icom.agency" background={similarweb}>
                Собирает статистику по доменам от сервиса similarweb.com.
            </ProjectCard>
            <ProjectCard title="Скриншотер" href="https://screenshot.icom.agency" background={screenshoter}>
                Проект создания скриншотов сайтов с заменой креативов на них.
            </ProjectCard>
        </div>
    );
}
