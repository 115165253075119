import React from 'react';

import { makeStyles } from '@mui/styles';
import { Paper, Box, Container } from '@mui/material';

import { LoginDialog } from './LoginDialog';

const useFormStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
    },
}));

function LoginScreen() {
    const classes = useFormStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xs">
                <Paper>
                    <Box p={2}>
                        <LoginDialog />
                    </Box>
                </Paper>
            </Container>
        </div>
    );
}

export { LoginScreen };
