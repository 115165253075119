import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, FormControl, FormHelperText, FormLabel, IconButton, Link } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { humanFileSize } from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
    fileName: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function FormFile({
    buttonText,
    accept = null,
    label,
    name,
    value,
    onChange = () => null,
    loading,
    error,
    required,
    helperText,
    multiple = null,
}) {
    const classes = useStyles();
    const ref = React.useRef();

    React.useEffect(() => {
        if (ref && ref.current) {
            if (!value) {
                ref.current.value = '';
            }
        }
    }, [value]);

    let errorText = '';

    if (error && error !== true) {
        errorText = error;
        error = true;
    }

    helperText = errorText + (errorText && helperText ? ', ' : '') + (helperText ? helperText : '');

    function triggerOnChange(value) {
        onChange({
            target: {
                type: 'file',
                name,
                value,
            },
        });
    }

    function handleChange(e) {
        if (e.target.files.length) {
            triggerOnChange(multiple ? e.target.files : e.target.files[0]);
        } else {
            triggerOnChange(null);
        }
    }

    let attachedFileTitle = null;
    let attachedFileUrl = null;

    if (value) {
        let files = [];

        if (multiple && value?.length) {
            files = Array.from(value);
        }

        if (!multiple && value) {
            files = [value];
        }

        if (files.length > 1) {
            let filesCount = files.length;
            let filesTotalSize = files.reduce((total, file) => total + file.size, 0);
            attachedFileTitle = 'Выбрано файлов: ' + filesCount + ', общим объёмом: ' + humanFileSize(filesTotalSize);
        } else if (files.length === 1) {
            attachedFileTitle = files[0].name + ' (' + humanFileSize(files[0].size) + ')';
            attachedFileUrl = files[0]?.url;
        }
    }

    return (
        <FormControl>
            <FormLabel required={required} error={error} disabled={loading}>
                {label}
            </FormLabel>
            <div className={classes.buttonsContainer}>
                <label style={{ display: 'inline-block' }}>
                    {!loading ? (
                        <input
                            ref={ref}
                            accept={accept}
                            style={{ display: 'none' }}
                            type="file"
                            multiple={multiple}
                            onChange={handleChange}
                        />
                    ) : null}
                    <Button
                        size="small"
                        variant="outlined"
                        disabled={loading}
                        component="span"
                        startIcon={<AttachFileIcon />}
                    >
                        {buttonText ? buttonText : 'Выбрать файл'}
                    </Button>
                </label>
                <span className={classes.fileName}>
                    {attachedFileUrl ? (
                        <Link href={attachedFileUrl} download>
                            {attachedFileTitle}
                        </Link>
                    ) : (
                        <span>{attachedFileTitle}</span>
                    )}
                    {value ? (
                        <IconButton disabled={loading} onClick={() => triggerOnChange(null)}>
                            <DeleteIcon />
                        </IconButton>
                    ) : null}
                </span>
            </div>
            <FormHelperText error={error} disabled={loading}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
}

export { FormFile };
