import { makeAutoObservable } from 'mobx';

import { regexpEscape } from '../lib/utils';

class AuthStore {
    constructor() {
        let data = localStorage.getItem('auth:data');

        if (data) {
            data = JSON.parse(data);
        }

        this.id = data ? data.id : null;
        this.key = data ? data.key : null;
        this.permissions = data ? data.permissions : [];

        makeAutoObservable(this);
    }

    setAuthorized(data) {
        this.id = data.id;
        this.key = data.key;
        this.permissions = data.permissions;
        localStorage.setItem('auth:data', JSON.stringify(data));
        document.cookie = 'authkey=' + encodeURIComponent(data.key) + '; path=/api; secure';
    }

    setUnauthorized() {
        this.id = null;
        this.key = null;
        this.permissions = [];
        localStorage.removeItem('auth:data');
        document.cookie = 'authkey=; path=/api; max-age=-1';
    }

    refreshAuthorization() {
        if (this.authTimer) {
            clearTimeout(this.authTimer);
            this.authTimer = null;
        }

        function deauthorize() {
            if (this.isAuthorized) {
                this.setUnauthorized();
            }
        }

        // Без каких-либо действий на сервере - прекращаем авторизацию через 180 мин
        this.authTimer = setTimeout(deauthorize.bind(this), 1000 * 60 * 179.5);
    }

    has(permissions) {
        if (permissions.indexOf('*') !== -1) {
            const searchPermissons = new RegExp(
                '^' +
                    permissions
                        .split('*')
                        .map((s) => regexpEscape(s))
                        .join('.*') +
                    '$'
            );

            const found = this.permissions.find((p) => searchPermissons.test(p));

            return !!found;
        }

        return !!this.permissions.find((p) => p === permissions);
    }

    get isAuthorized() {
        return null !== this.key;
    }
}

const authStore = new AuthStore();

export { authStore };
