import React from 'react';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { messagesStore } from '../../stores/messagesStore';
import { FormFile } from '../../components/common/FormFile';
import { FormCheckbox } from '../../components/common/FormCheckbox';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { Validator } from '../../lib/utils';

function PlansForm({ objectId, onClose = () => null, onSave = () => null }) {
    const isMounted = useMountedState();

    const [showIgnore, setShowIgnore] = React.useState(false);

    const { data, setData, setErrors, setProcessing, fieldProps, formProps } = useDataForm({ handleChangeMiddleware });

    function handleChangeMiddleware() {
        setProcessing(false);
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([{ file: 'required' }]);

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);

            (async () => {
                try {
                    let file = await api.upload(data.file);

                    let result = await api.plansCreate({
                        file: file.path,
                        is_ignore_errors: !!data?.is_ignore_errors,
                    });

                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                                if (result.errors?.fileCode === 'earlyMediaFinishOn') {
                                    setShowIgnore(true);
                                } else {
                                    setData({ file: null });
                                }
                            } else {
                                messagesStore.setSuccessSaved(true);
                                onSave();
                            }
                        }
                    }
                } catch (e) {
                    if (isMounted()) {
                        setErrors({ file: e.message });
                    }
                } finally {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                }
            })();
        }
    }

    return (
        <DataForm
            {...formProps()}
            editing={!!objectId}
            createText="Загрузка медиплана"
            onSave={handleSave}
            onClose={onClose}
        >
            <div style={{ textAlign: 'center' }}>
                <FormFile
                    {...fieldProps('file')}
                    accept="application/vnd.ms-excel.sheet.macroEnabled.12"
                    label="Файл медиаплана"
                    required
                />
                {showIgnore ? (
                    <div>
                        <FormCheckbox {...fieldProps('is_ignore_errors')} label="Я чётко понимаю что я делаю" />
                    </div>
                ) : null}
            </div>
        </DataForm>
    );
}

export { PlansForm };
