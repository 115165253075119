import React from 'react';

import { FormAutocompleteMultiple } from './common/FormAutocompleteMultiple';
import { dictionariesStore } from '../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';

const BrandsSelect = observer(({ label = 'Бренд', clientIds = [], ...props }) => {
    let list = dictionariesStore.brands;

    if (clientIds && clientIds.length) {
        list = list.filter((v) => {
            if (!v.client_ids) {
                return false;
            }

            const search = !Array.isArray(clientIds) ? [clientIds] : [...clientIds];
            return Array.from(v.client_ids).some((id) => search.includes(id));
        });
    }

    return <FormAutocompleteMultiple list={list} label={label} {...props} />;
});

export { BrandsSelect };
