import { useSearchParams } from 'react-router-dom';

export function useSearchParamsState(name, defaultValue = null) {
    const [searchParams, setSearchParams] = useSearchParams();

    const param = searchParams.get(name) || defaultValue;

    function setParam(value) {
        if (!value) {
            searchParams.delete(name);
        } else {
            searchParams.set(name, value);
        }
        setSearchParams(new URLSearchParams(searchParams));
    }

    return [param, setParam];
}
