import React from 'react';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab, Box } from '@mui/material';
import { FactsImprovado } from './FactsImprovado';
import { FactsGa } from './FactsGa';
import { FactsSm } from './FactsSm';
import { GaIcon } from '../../components/GaIcon';
import ImprovadoLogo from '../../pics/improvado.jpg';
import SportmasterLogo from '../../pics/sportmaster.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index} {...other}>
            {value === index && (
                <Box ml={2} mt={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: { flexGrow: 1, display: 'flex' },
}));

export function FactsDetails({ planId, filterData }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <Box mt={1}>
            <Tabs orientation="horizontal" variant="scrollable" value={value} onChange={handleChange}>
                <Tab icon={<img src={ImprovadoLogo} width="28" height="28" alt="" />} label="Импровадо" />
                <Tab icon={<GaIcon />} label="Google Analytics" />
                <Tab icon={<img src={SportmasterLogo} width="28" height="28" alt="" />} label="Спортмастер" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <FactsImprovado planId={planId} filterData={filterData} />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                <FactsGa planId={planId} filterData={filterData} />
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabPanel}>
                <FactsSm planId={planId} filterData={filterData} />
            </TabPanel>
        </Box>
    );
}
