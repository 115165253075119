import React from 'react';

import { observer } from 'mobx-react-lite';

import { Tooltip, TableCell, Collapse, IconButton, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { api } from '../../lib/api';
import { useDataTable } from '../../components/common/DataTable';
import { CommonDataTable } from '../../components/CustomDataTable';
import { PairsForm } from './Form';
import { PairsPairsList } from './PairsPairsList';

import { useFormStyles } from '../../hooks/useFormStyles';
import { authStore } from '../../stores/authStore';

async function rowsLoader(params) {
    return (await api.pairsList({ ...params }))?.rows;
}

async function rowsDeleter(ids) {
    return await api.pairsDelete(ids);
}

export const PairsList = observer(function () {
    const hasEditPermissions = authStore.has('pairs.edit');

    const classes = useFormStyles();
    const [editId, setEditId] = React.useState(false);

    const columns = [
        { field: 'employees_count', title: 'Кол-во сотрудников' },
        { field: 'pairs_count', title: 'Кол-во пар' },
        { field: 'created_at', title: 'Дата генерации', type: 'datetime' },
        { field: 'created_by_name', title: 'Кто сгенерировал' },
    ];

    const { tableProps, tableActions } = useDataTable({
        columns,
        rowsLoader,
        rowsDeleter: hasEditPermissions ? rowsDeleter : undefined,
    });

    const [togglePairsList, setTogglePairsList] = React.useState({});

    function renderRowButtons({ rowId }) {
        const toggle = () =>
            setTogglePairsList((toggles) => ({
                ...toggles,
                [rowId]: !toggles[rowId],
            }));

        return (
            <Tooltip title="Показать список пар">
                <IconButton onClick={toggle}>
                    {!togglePairsList[rowId] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
            </Tooltip>
        );
    }

    function renderAfterRow({ rowId }) {
        return (
            <TableRow>
                <TableCell colSpan={columns.length} className={classes.tableDetailsRow}>
                    <Collapse in={togglePairsList[rowId]} unmountOnExit style={{ marginLeft: 50 }}>
                        <PairsPairsList pairId={rowId} />
                    </Collapse>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <React.Fragment>
            {false !== editId && (
                <PairsForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CommonDataTable
                {...tableProps}
                disableSelection={!hasEditPermissions}
                onCreate={hasEditPermissions ? () => setEditId(null) : undefined}
                createButtonText="Сгенерировать пары"
                renderRowButtons={renderRowButtons}
                renderAfterRow={renderAfterRow}
            />
        </React.Fragment>
    );
});
