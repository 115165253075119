import React from 'react';

import {
    Alert,
    Box,
    FormLabel,
    LinearProgress,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import {
    ResponsiveContainer,
    Label,
    Pie,
    PieChart,
    Tooltip,
    Text,
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Line,
    Legend,
} from 'recharts';

import moment from 'moment';

import { formatValue } from '../../components/common/DataTable';
import { getColumn } from './hooks';
import { ValueWithIndicator } from './ValueWithIndicator';
import { FormSimpleSelect } from '../../components/common/FormSimpleSelect';
import { api } from '../../lib/api';
import { useFormStyles } from '../../hooks/useFormStyles';

function shuffle(array) {
    return array.sort(() => Math.random() - 0.5);
}

const fontSize = 12;
const borderRadius = 5;

const COLORS = shuffle([
    '#115293',
    '#381f75',
    '#7a7d1e',
    '#af861f',
    '#561571',
    '#87103f',
    '#486f27',
    '#ab5600',
    '#00544a',
    '#a13311',
    '#932020',
    '#006974',
    '#561571',
]);

function integerFormatter(value) {
    return formatValue({ value, column: { type: 'integer' } });
}

function floatOrNull(value) {
    value = parseFloat(value);
    if (isNaN(value)) {
        return null;
    }
    return value;
}

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
});

function MetricsSelect(props) {
    function createOption(field) {
        return { id: field, name: getColumn(field).title };
    }

    const list = [
        createOption('total_cost'),
        createOption('imp_impressions'),
        createOption('imp_clicks'),
        createOption('app_installs'),
        createOption('app_orders'),
        createOption('total_order_revenues'),
    ];

    return <FormSimpleSelect disableFirstOption list={list} {...props} />;
}

function useTooltipStyles() {
    const theme = useTheme();

    const itemStyle = {
        color: theme.palette.text.primary,
    };

    const contentStyle = {
        ...itemStyle,
        fontSize,
        borderRadius,
        background: theme.palette.background.default,
    };

    return { itemStyle, contentStyle };
}

function CompletnessPercent({ start, finish }) {
    if (!start) {
        return null;
    }

    if (!finish) {
        return null;
    }

    const fullPeriod = moment(finish) - moment(start);
    const completnessPeriod = moment() > moment(start) ? moment() - moment(start) : 0;
    const completnessPercent = completnessPeriod < fullPeriod ? (completnessPeriod / fullPeriod) * 100 : 100;

    return (
        <div>
            <Box>Процент выполнения: {completnessPercent.toFixed(0)}%</Box>
            <LinearProgress value={completnessPercent} variant="determinate" sx={{ height: 10, borderRadius: 5 }} />
        </div>
    );
}

function MetricsTable({ loading = false, columns = [], data = {}, height = 400 }) {
    return (
        <React.Fragment>
            {loading ? (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={height}
                    sx={{ borderRadius: 10 }}
                />
            ) : (
                <React.Fragment>
                    <CompletnessPercent start={data?.start_on_min} finish={data?.finish_on_max} />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Метрика</TableCell>
                                    <TableCell align="right">План</TableCell>
                                    <TableCell align="right">Факт</TableCell>
                                    <TableCell align="center">% выполнения</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {columns.map((column, index) => {
                                    const value = data?.[column?.field];
                                    const valueFormatted = value ? formatValue({ value, column }) : null;

                                    const fcValue = column?.fc?.field ? data?.[column.fc.field] : null;
                                    const fcColumn = fcValue ? column.fc : null;
                                    const fcValueFormatted = fcColumn
                                        ? formatValue({ value: fcValue, column: fcColumn })
                                        : null;

                                    const percent = fcValue !== 0 ? (value / fcValue) * 100 : null;

                                    return (
                                        <TableRow key={index}>
                                            <TableCell variant="head">{column.title}</TableCell>
                                            <TableCell align="right">
                                                <ValueWithIndicator
                                                    value={fcValueFormatted}
                                                    plan={fcValue}
                                                    fact={value}
                                                    greaterFactIsBad
                                                />
                                            </TableCell>
                                            <TableCell align="right">{valueFormatted}</TableCell>
                                            <TableCell align="center">
                                                {formatValue({ value: percent, column: { type: 'integer' } })}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

function DonutMetricChart({ loading = false, data = [], metric = null, onMetricChange = () => null, height = 400 }) {
    const theme = useTheme();
    const { itemStyle, contentStyle } = useTooltipStyles();

    function handleMetricChange(e) {
        onMetricChange(e.target.value);
    }

    const totalValue = data.reduce((total, data) => (data.value ? total + data.value : total), 0);

    return (
        <React.Fragment>
            {loading ? (
                <div>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={220}
                        height={50}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: height * 0.15,
                        }}
                        sx={{ borderRadius: 10 }}
                    />
                    <Skeleton
                        variant="circular"
                        animation="wave"
                        width={height * 0.8}
                        height={height * 0.8}
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    />
                </div>
            ) : (
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <MetricsSelect onChange={handleMetricChange} value={metric} />
                    </div>
                    {totalValue ? (
                        <ResponsiveContainer height={height}>
                            <PieChart>
                                <Pie
                                    data={data}
                                    nameKey="name"
                                    dataKey="value"
                                    outerRadius="90%"
                                    innerRadius="60%"
                                    paddingAngle={2}
                                >
                                    <Label
                                        content={(p) => (
                                            <Text
                                                x={p.viewBox.cx}
                                                y={p.viewBox.cy}
                                                width={p.viewBox.innerRadius}
                                                fill={theme.palette.text.primary}
                                                fontSize={theme.typography.h5.fontSize}
                                                textAnchor="middle"
                                                verticalAnchor="middle"
                                            >
                                                {p.value}
                                            </Text>
                                        )}
                                        position="center"
                                        value={integerFormatter(totalValue)}
                                    />
                                </Pie>
                                <Legend />
                                <Tooltip
                                    contentStyle={contentStyle}
                                    itemStyle={itemStyle}
                                    formatter={(v) => integerFormatter(v)}
                                    separator=": "
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    ) : (
                        <Alert severity="info" sx={{ m: 1 }}>
                            Данные отсутствуют
                        </Alert>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

function DoubleMetricsChart({
    loading = false,
    data = [],
    metric1 = null,
    onMetric1Change = () => null,
    metric2 = null,
    onMetric2Change = () => null,
    interval = 'daily',
    onIntervalChange = () => null,
    height = 400,
}) {
    const theme = useTheme();
    const classes = useFormStyles();
    const { contentStyle } = useTooltipStyles();

    function handleMetric1Change(e) {
        onMetric1Change(e.target.value);
    }

    function handleMetric2Change(e) {
        onMetric2Change(e.target.value);
    }

    function handleIntervalChange(e) {
        onIntervalChange(e.target.value);
    }

    return (
        <React.Fragment>
            <div style={{ padding: theme.spacing(1) }}>
                <div className={classes.fieldsGroup} style={{ justifyContent: 'space-between' }}>
                    <div className={classes.fieldsGroup} style={{ alignItems: 'center' }}>
                        <MetricsSelect onChange={handleMetric1Change} value={metric1} />
                        <FormLabel>и</FormLabel>
                        <MetricsSelect onChange={handleMetric2Change} value={metric2} />
                    </div>
                    <div className={classes.fieldsGroup} style={{ alignItems: 'center' }}>
                        <ToggleButtonGroup size="small" value={interval} exclusive>
                            <ToggleButton value="daily" onClick={handleIntervalChange}>
                                День
                            </ToggleButton>
                            <ToggleButton value="weekly" onClick={handleIntervalChange}>
                                Неделя
                            </ToggleButton>
                            <ToggleButton value="monthly" onClick={handleIntervalChange}>
                                Месяц
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
            </div>
            <div style={{ padding: theme.spacing(1) }}>
                {loading ? (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={height}
                        sx={{ borderRadius: 10 }}
                    />
                ) : data?.length ? (
                    <ResponsiveContainer width="100%" height={height}>
                        <LineChart data={data}>
                            <XAxis
                                dataKey="data_on"
                                tickFormatter={(v) => moment(v).format('D MMM')}
                                tick={{ fontSize }}
                            />
                            <YAxis
                                width={70}
                                tickFormatter={(v) => integerFormatter(v)}
                                padding={{ top: 30 }}
                                tick={{ fontSize }}
                            />
                            <Legend
                                margin={{ top: 10, left: 10, bottom: 10, right: 10 }}
                                formatter={(n) => getColumn(n).title}
                                wrapperStyle={{ fontSize }}
                            />
                            <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                            <Line type="monotone" dataKey={metric1} stroke="#8884d8" strokeWidth={3} />
                            <Line type="monotone" dataKey={metric2} stroke="#82ca9d" strokeWidth={3} />
                            <Tooltip
                                contentStyle={contentStyle}
                                labelFormatter={(v) => moment(v).format('D MMM')}
                                formatter={(v, n) => [integerFormatter(v), getColumn(n).title]}
                                separator=": "
                            />
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <Alert severity="info">Данные отсутствуют</Alert>
                )}
            </div>
        </React.Fragment>
    );
}

export function Charts({
    instruments,
    totals,
    filterData,
    metric = 'total_cost',
    onMetricChange = () => null,
    metric1 = 'total_cost',
    onMetric1Change = () => null,
    metric2 = 'app_orders',
    onMetric2Change = () => null,
    interval = 'daily',
    onIntervalChange = () => null,
}) {
    const classes = useClasses();

    const [loadingDaily, setLoadingDaily] = React.useState(true);
    const [dailyData, setDailyData] = React.useState([]);

    const filterDataSerialized = JSON.stringify(filterData);

    React.useEffect(() => {
        let discard = false;

        setLoadingDaily(true);

        api.plansReportsFactsDaily(interval, JSON.parse(filterDataSerialized)).then((data) => {
            if (!discard) {
                setLoadingDaily(false);
            }

            if (!discard && data) {
                setDailyData(data.rows.sort((a, b) => moment(a.data_on) - moment(b.data_on)));
            }
        });

        return () => (discard = true);
    }, [filterDataSerialized, interval]);

    const loadingData = !instruments || !Array.isArray(instruments) || instruments.length === 0;

    if (loadingData) {
        instruments = [];
    }

    const piesData = instruments.map((data, index) => {
        const fill = COLORS[index % COLORS.length];
        const value = floatOrNull(data.totals[metric]);

        return {
            code: data.code,
            name: data.name,
            value,
            fill,
        };
    });

    const totalsColumns = [
        { ...getColumn('total_cost'), fc: getColumn('fc_cost') },
        { ...getColumn('imp_impressions'), fc: getColumn('fc_impressions') },
        { ...getColumn('cpm'), fc: getColumn('fc_cpm') },
        { ...getColumn('imp_clicks'), fc: getColumn('fc_clicks') },
        { ...getColumn('cpc'), fc: getColumn('fc_cpc') },
        { ...getColumn('imp_video_views') },
        { ...getColumn('cpv'), fc: getColumn('fc_cpv') },
        { ...getColumn('app_installs') },
        { ...getColumn('cpi') },
        { ...getColumn('total_order_revenues') },
        { ...getColumn('cpo') },
        { ...getColumn('drr') },
    ];

    const dailyDataCurrent = dailyData.map((v) => ({
        data_on: v.data_on,
        [metric1]: floatOrNull(v[metric1]),
        [metric2]: floatOrNull(v[metric2]),
    }));

    const metricGraphHeight = 400;
    const compareGraphHeight = 400;

    return (
        <div>
            <Box p={2} className={classes.container}>
                <div style={{ flexGrow: 4, flexBasis: 400 }}>
                    <MetricsTable loading={loadingData} columns={totalsColumns} data={totals} />
                </div>
                <div style={{ flexGrow: 6, minWidth: 350, flexBasis: 400 }}>
                    <DonutMetricChart
                        loading={loadingData}
                        height={metricGraphHeight}
                        data={piesData}
                        metric={metric}
                        onMetricChange={onMetricChange}
                    />
                </div>
            </Box>
            <DoubleMetricsChart
                loading={loadingDaily}
                height={compareGraphHeight}
                data={dailyDataCurrent}
                metric1={metric1}
                onMetric1Change={onMetric1Change}
                metric2={metric2}
                onMetric2Change={onMetric2Change}
                interval={interval}
                onIntervalChange={onIntervalChange}
            />
        </div>
    );
}
