import React from 'react';

import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Button, Skeleton } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';

import moment from 'moment';

import { CommonDataTable } from '../../components/CustomDataTable';
import { api } from '../../lib/api';
import { blue, green } from '@mui/material/colors';
import { FormText } from '../../components/common/FormText';
import { downloadCsv, escapeCsv } from '../../lib/utils';

export function FactsSm({ planId, filterData }) {
    const [loading, setLoading] = React.useState(true);
    const [allDataRows, setDataRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filter, setFilter] = React.useState('');

    const filterDataFacts = JSON.stringify(filterData?.facts);

    React.useEffect(() => {
        let discard = false;

        setLoading(true);

        const data = filterDataFacts ? JSON.parse(filterDataFacts) : null;
        api.plansReportsFactsSm(planId, data).then((data) => {
            if (!discard) {
                setLoading(false);
            }

            if (!discard && data) {
                setDataRows(data.rows);
            }
        });

        return () => (discard = true);
    }, [planId, filterDataFacts]);

    const dataRows = allDataRows.filter((row) => {
        if (!filter) {
            return true;
        } else {
            for (let k of ['campaign_id', 'campaign_name', 'adset_id', 'adset_name', 'ad_name']) {
                if ((row[k] + '').toLowerCase().includes(filter.toLowerCase())) {
                    return true;
                }
            }
            return false;
        }
    });

    const columns = [
        { field: 'data_on', title: 'Дата', type: 'date' },
        { field: 'platform', title: 'Платформа' },
        { field: 'campaign_name', title: 'Кампания' },
        { field: 'campaign_id' },
        { field: 'ad_name' },
        { field: 'adset_id' },
        { field: 'adset_name' },
        { field: 'iid' },
        { field: 'installs', title: 'Установок', type: 'integer' },
        { field: 'sessions', title: 'Сессий', type: 'integer' },
        { field: 'auth_sessions', title: 'Установок с авторизацией', type: 'integer' },
        { field: 'orders', title: 'Заказов', type: 'integer' },
        { field: 'order_revenues', title: 'Доход', type: 'integer' },
    ];

    const rowsCount = null === dataRows ? 0 : dataRows.length;
    const rows = null === dataRows ? [] : dataRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    let chartRows = new Map();

    if (null !== dataRows) {
        dataRows.forEach((data) => {
            const dateTitle = moment(data.data_on).format('D MMM');

            let agg = {
                Дата: dateTitle,
                Установки: 0,
                Сессии: 0,
            };

            if (chartRows.has(data.data_on)) {
                agg = chartRows.get(data.data_on);
            } else {
                chartRows.set(data.data_on, agg);
            }

            if (!isNaN(data.installs)) {
                agg['Установки'] += data.installs;
            }

            if (!isNaN(data.sessions)) {
                agg['Сессии'] += data.sessions;
            }
        });
    }

    function handleFilterChange(e) {
        setFilter(e.target.value);
        setPage(0);
    }

    function downloadData() {
        const csv = dataRows.map((data) => escapeCsv(columns.map((column) => data[column.field])));
        const headers = escapeCsv(columns.map((column) => column.title ?? column.field));
        csv.unshift(headers);
        downloadCsv('sm_data.csv', csv.join('\n'));
    }

    chartRows = Array.from(chartRows.values());

    if (loading) {
        return (
            <Skeleton
                variant="rectangular"
                animation="wave"
                width="calc(100vw - 500px)"
                height={350}
                sx={{ borderRadius: 10 }}
            />
        );
    }

    return (
        <div>
            {chartRows.length ? (
                <div style={{ width: 'calc(100vw - 500px)' }}>
                    <ResponsiveContainer width="100%" height={350}>
                        <BarChart data={chartRows}>
                            <XAxis dataKey="Дата" />
                            <Bar dataKey="Установки" fill={green[500]} />
                            <Bar dataKey="Сессии" fill={blue[500]} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : null}
            <CommonDataTable
                columns={columns}
                rows={rows}
                rowsCount={rowsCount}
                rowsPerPage={rowsPerPage}
                onRowsPerPage={setRowsPerPage}
                page={page}
                onPage={setPage}
                inlineTable
                maximizeWidth={false}
                toolbarButtons={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button onClick={downloadData} disabled={loading} variant="text" startIcon={<AttachmentIcon />}>
                            CSV
                        </Button>
                        <FormText
                            onChange={handleFilterChange}
                            value={filter}
                            margin="none"
                            size="small"
                            label="Фильтр"
                        />
                    </Box>
                }
            />
        </div>
    );
}
