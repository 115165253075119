import React from 'react';

import { makeStyles } from '@mui/styles';
import { Typography, Avatar, Box, Link } from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { Login } from './Login';
import { Reminder } from './Reminder';

import Logo from '../../pics/logo.png';
import { isOAuthProcess } from '../../lib/utils';

const useFormStyles = makeStyles((theme) => ({
    loginIcon: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    logo: {
        maxWidth: 200,
        maxHeight: 100,
        margin: theme.spacing(1),
        filter: theme.palette.mode === 'dark' ? 'invert(40%)' : 'none',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

function LoginDialog() {
    const classes = useFormStyles();
    const [loginAction, setLoginAction] = React.useState('login');

    return (
        <div className={classes.dialogContent}>
            <Avatar className={classes.loginIcon}>
                <LockOutlinedIcon />
            </Avatar>
            <img src={Logo} className={classes.logo} alt="" />
            {!isOAuthProcess() ? (
                <Typography variant="body1" color="primary" paragraph>
                    {process.env.REACT_APP_TITLE}
                </Typography>
            ) : null}
            {loginAction === 'login' && (
                <React.Fragment>
                    <Login />
                    <Box mt={1} mb={1}>
                        <Link component="button" onClick={() => setLoginAction('reminder')} variant="body2">
                            Забыли пароль?
                        </Link>
                    </Box>
                </React.Fragment>
            )}
            {loginAction === 'reminder' && (
                <>
                    <Reminder />
                    <Box mt={1} mb={1}>
                        <Link component="button" onClick={() => setLoginAction('login')} variant="body2">
                            Войти в систему
                        </Link>
                    </Box>
                </>
            )}
        </div>
    );
}

export { LoginDialog };
