import _ from 'lodash';

import { api } from './api';

export class Validator {
    constructor(inputData) {
        this.errors = {};
        this.data = { ...inputData };
    }

    checkAll(rules) {
        for (let rule of rules) {
            let path,
                check,
                validate = false;

            if (Object.keys(rule).length === 1) {
                path = Object.keys(rule)[0];
                check = Object.values(rule)[0];
            } else {
                if (!rule.path) {
                    throw new Error("'path' key required");
                }
                path = rule.path;

                if (!rule.check) {
                    throw new Error("'check' key required");
                }
                check = rule.check;

                validate = !!rule.validate;
            }

            this.check(path, check, validate);
        }

        return this;
    }

    check(path, checks, validateOnly) {
        checks = checks.split(/\s*,\s*/);
        let value = _.get(this.data, path);
        let error = this.checkForError(value, checks);
        if (error) {
            if (!validateOnly) {
                this.errors[path] = error;
            }
            return false;
        }
        return true;
    }

    validate(path, checks) {
        return this.check(path, checks, true);
    }

    checkForError(value, checks) {
        let error = null;
        checkloop: for (let check of checks) {
            switch (check) {
                case 'required':
                    if (!value || (value.length && value.length === 0)) {
                        error = 'Обязательно для заполнения';
                        break checkloop;
                    }
                    break;
                case 'number':
                    if (value?.length) {
                        if (isNaN(parseFloat(value))) {
                            error = 'Неверное число';
                            break checkloop;
                        }
                    }
                    break;
                case 'date':
                    if (value) {
                        let date = new Date(value);
                        if (date.toString() === 'Invalid Date') {
                            error = 'Неверная дата';
                            break checkloop;
                        }
                    }
                    break;
                case 'email':
                    if (value?.length) {
                        if (!value.match(/^\w+[\w-]*(\.\w[\w-]*)*@\w[\w-]*\.(\w[\w-]*\.)*\w{2,}$/su)) {
                            error = 'Неверный e-mail';
                            break checkloop;
                        }
                    }
                    break;
                default:
                    throw new Error('Unknown rule name: ' + check);
            }
        }
        return error;
    }

    setError(path, message) {
        this.errors[path] = message;
    }

    getErrors() {
        return this.errors;
    }

    isHasErrors() {
        return Object.keys(this.errors).length > 0;
    }
}

export function humanFileSize(size = 0) {
    if (!size) {
        size = 0;
    }

    let postfix = ['байт', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Еб', 'Зб', 'Йб'];

    let i = 0;
    while (size >= 1024) {
        size /= 1024;
        if (typeof postfix[i] == 'undefined') {
            break;
        }
        i++;
    }

    return size.toFixed(i ? 2 : 0) + ' ' + postfix[i];
}

function getOAuthParams() {
    const params = new URLSearchParams(window.location.search);
    const oauthClientId = params.get('client_id');
    const oauthRedirectUri = params.get('redirect_uri');
    const oauthState = params.get('state');
    return { oauthClientId, oauthRedirectUri, oauthState };
}

export function isOAuthProcess() {
    const { oauthClientId, oauthRedirectUri, oauthState } = getOAuthParams();
    return oauthClientId && oauthRedirectUri && oauthState;
}

export function processOAuth() {
    const { oauthClientId, oauthRedirectUri, oauthState } = getOAuthParams();

    api.oauthCode({
        client_id: oauthClientId,
        redirect_uri: oauthRedirectUri,
        state: oauthState,
    }).then((data) => {
        if (data && data.redirect_uri) {
            window.location.assign(data.redirect_uri);
        }
    });
}

function _escapeCsv(data) {
    return data ? '"' + (data + '').replace(/"/g, '""') + '"' : '';
}

export function regexpEscape(text) {
    const specials = ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\'];
    return text.replace(new RegExp('(\\' + specials.join('|\\') + ')', 'g'), '\\$1');
}

export function escapeCsv(data) {
    if (Array.isArray(data)) {
        return data.map((v) => _escapeCsv(v)).join(';');
    }
    return _escapeCsv(data);
}

export function downloadCsv(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
