import React from 'react';

import { observer } from 'mobx-react-lite';

import { FormControl, Button, Chip } from '@mui/material';

import { api } from '../../lib/api';
import { useDataTable } from '../../components/common/DataTable';
import { CommonDataTable } from '../../components/CustomDataTable';
import { AdminRolesForm } from './Form';
import { useFormStyles } from '../../hooks/useFormStyles';
import { useDataForm } from '../../components/common/DataForm';
import { FormText } from '../../components/common/FormText';
import { authStore } from '../../stores/authStore';

function FilterContent({ onFilter, initialData }) {
    const classes = useFormStyles();
    const { data, fieldProps } = useDataForm({ initialData });

    function handleFilter() {
        onFilter(data);
    }

    return (
        <div className={classes.fieldsGroup}>
            <FormText {...fieldProps('filter')} label="Поиск" />
            <FormControl>
                <Button onClick={handleFilter}>Показать</Button>
            </FormControl>
        </div>
    );
}

async function rowsLoader(params) {
    return (await api.adminRolesList({ ...params }))?.rows;
}

async function rowsDeleter(ids) {
    return await api.adminRolesDelete(ids);
}

export const AdminRolesList = observer(function () {
    const hasEditPermissions = authStore.has('accounts.edit');

    const columns = [
        { field: 'name', title: 'Название' },
        {
            field: 'permissions',
            title: 'Разрешения',
            sortable: false,
            renderValue: ({ value }) =>
                value.map((v, i) => (
                    <Chip
                        key={i}
                        label={v.name}
                        size="small"
                        variant="outlined"
                        sx={{ height: 'auto', fontSize: '0.7rem', m: '1px' }}
                    />
                )),
        },
        { field: 'created_at', type: 'datetime', title: 'Дата создания' },
    ];

    const [editId, setEditId] = React.useState(false);

    const { tableProps, tableActions } = useDataTable({ columns, rowsLoader, rowsDeleter, filterData: { filter: '' } });

    function handleFilterChange(value) {
        tableActions.applyFilterData(value);
        tableActions.setFilterOpen(false);
    }

    return (
        <React.Fragment>
            {false !== editId && (
                <AdminRolesForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CommonDataTable
                {...tableProps}
                filterContent={<FilterContent onFilter={handleFilterChange} initialData={tableProps.filterData} />}
                onCreate={hasEditPermissions ? () => setEditId(null) : undefined}
                onEdit={hasEditPermissions ? (id) => setEditId(id) : undefined}
                disableSelection={!hasEditPermissions}
            />
        </React.Fragment>
    );
});
