import React from 'react';

import { useParams } from 'react-router-dom';
import {
    Tooltip,
    TableCell,
    Collapse,
    IconButton,
    TableRow,
    Badge,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ImageIcon from '@mui/icons-material/Image';
import { ExcelIcon } from '../../components/ExcelIcon';

import _ from 'lodash';
import moment from 'moment';

import { api } from '../../lib/api';
import { DataTableCell, useDataTable } from '../../components/common/DataTable';
import { CommonDataTable } from '../../components/CustomDataTable';
import { FactsDetails } from './FactsDetails';

import { useFormStyles } from '../../hooks/useFormStyles';
import { useMountedState } from '../../hooks/useMountedState';
import { useSearchParamsState } from '../../hooks/useSearchParamsState';
import { useColumns } from './hooks';
import { FilterContent } from './FilterContent';
import { isFilterEmpty } from './utils';
import { Charts } from './Charts';
import { downloadCsv, escapeCsv } from '../../lib/utils';

function TotalRow({ data, columns }) {
    if (!data) {
        return null;
    }

    // Так как колонки могут скрывать, надо найти последнюю не скрытую колонку
    // в которой мы можем вывести слово "ИТОГО"
    let totalColumn = null;

    for (let column of columns) {
        if (column.asTotal && !column.hidden) {
            totalColumn = column;
        }
    }

    const TotalCell = () => (
        <TableCell align="center">
            <b>ИТОГО</b>
        </TableCell>
    );

    return (
        <TableRow>
            {totalColumn ? <TableCell /> : <TotalCell />}
            {columns.map((column, index) => {
                if (column.asTotal) {
                    if (column.hidden) {
                        return null;
                    }

                    if (column === totalColumn) {
                        return <TotalCell key={index} />;
                    }

                    return <TableCell key={index} />;
                }

                return <DataTableCell key={index} column={column} row={data} rowId="TOTALS" />;
            })}
        </TableRow>
    );
}

function DetailRows({ rowId, row, columns, showFacts = false, filterData }) {
    const classes = useFormStyles();

    let creatives = [];

    if (row._creatives && row._creatives.length) {
        creatives = row._creatives.map((creativeRow, rowIndex) => (
            <TableRow key={rowIndex}>
                <TableCell />
                {columns.map((column, colIndex) => (
                    <DataTableCell key={colIndex} column={column} row={creativeRow} rowId={creativeRow.creative} />
                ))}
            </TableRow>
        ));
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={columns.length + 2} className={classes.tableDetailsRow}>
                    <Collapse in={showFacts} unmountOnExit style={{ marginLeft: 50 }}>
                        <FactsDetails planId={rowId} filterData={filterData} />
                    </Collapse>
                </TableCell>
            </TableRow>
            {creatives}
        </React.Fragment>
    );
}

export function PlansReportsList() {
    const { id: planId } = useParams();
    const isMounted = useMountedState();
    const { columns } = useColumns();
    const [creatives, setCreatives] = React.useState(null);
    const [chartsOpen, setChartsOpen] = useSearchParamsState('charts');
    const [toggleFacts, setToggleFacts] = React.useState({});

    const [chartsInterval, setChartsInterval] = useSearchParamsState('interval', 'daily');
    const [chartsMetric, setChartsMetric] = useSearchParamsState('metric', 'total_cost');
    const [chartsMetric1, setChartsMetric1] = useSearchParamsState('metric1', 'total_cost');
    const [chartsMetric2, setChartsMetric2] = useSearchParamsState('metric2', 'app_orders');

    const loadedData = React.useRef(null);

    const rowsLoader = React.useCallback(
        async (params) => {
            const requestParams = { ...params };

            delete requestParams.offset;
            delete requestParams.limit;
            delete requestParams.order;
            delete requestParams.orderDir;

            function sorter(a, b, dir) {
                if (!a) {
                    return 1;
                }

                if (!b) {
                    return -1;
                }

                const n = dir?.toLowerCase() === 'desc' ? -1 : 1;

                if (!isNaN(+a) && !isNaN(+b)) {
                    return (+a - +b) * n;
                }

                if (moment(a).isValid() && moment(b).isValid()) {
                    return (moment(a) - moment(b)) * n;
                }

                if (a > b) {
                    return 1 * n;
                } else if (a < b) {
                    return -1 * n;
                }

                return 0;
            }

            let resultData = null;

            if (!isFilterEmpty(params?.filterData)) {
                if (loadedData.current && _.isEqual(loadedData.current.requestParams, requestParams)) {
                    resultData = loadedData.current;
                } else {
                    const apiResponse = await api.plansReportsList({ ...requestParams });
                    if (apiResponse?.rows) {
                        resultData = apiResponse;
                        loadedData.current = { ...resultData, requestParams };
                    } else {
                        loadedData.current = null;
                    }
                    setCreatives(null);
                }
            }

            if (resultData) {
                if (params.order) {
                    resultData.rows.sort((a, b) => sorter(a?.[params.order], b?.[params.order], params?.orderDir));
                }
                return _.cloneDeep(resultData.rows.slice(params.offset, params.offset + params.limit));
            } else {
                return [];
            }
        },
        [loadedData]
    );

    function populateWithCreatives(row) {
        row._creatives = null;
        if (creatives && creatives[row.id]) {
            row._creatives = creatives[row.id];
        }
        return row;
    }

    const dataRows = (loadedData.current?.rows || []).map(populateWithCreatives);
    const dataTotals = loadedData.current?.totals && dataRows?.length ? loadedData.current.totals : null;
    const dataInstrumentsTotal = loadedData.current?.instruments_totals ?? null;

    const { tableProps, tableParams, tableActions } = useDataTable({
        columns,
        columnsStorageKey: 'columns:plans/reports',
        filterDataMiddleware: (data) => {
            if (planId) {
                _.set(data, 'plans.id', planId);
            } else {
                _.unset(data, 'plans.id');
            }
            return data;
        },
        rowsLoader,
    });

    tableProps.rows = tableProps.rows.map(populateWithCreatives);

    if (isFilterEmpty(tableProps.filterData)) {
        tableProps.filterOpen = true;
    }

    const creativeColumn = columns.find((column) => column.field === 'creative');

    async function loadCreatives(loadCampaignId = null) {
        const data = !loadCampaignId
            ? await api.plansReportsCreativesAll({ ...tableParams })
            : await api.plansReportsCreatives(loadCampaignId, { ...tableParams });

        setCreatives((oldCreatives) => {
            const newCreatives = _.cloneDeep(null === oldCreatives ? {} : oldCreatives);

            if (loadCampaignId) {
                newCreatives[loadCampaignId] = [];
            }

            if (data?.rows) {
                data.rows.forEach((row) => {
                    const campaignId = row.plan_campaign_id;
                    if (!newCreatives[campaignId]) {
                        newCreatives[campaignId] = [];
                    }
                    newCreatives[campaignId].push(row);
                });
            }

            return newCreatives;
        });
    }

    function handleCreativesUnload(rowId) {
        setCreatives((oldCreatives) => {
            let newCreatives = _.cloneDeep(oldCreatives);
            delete newCreatives[rowId];
            if (Object.keys(newCreatives).length === 0) {
                newCreatives = null;
            }
            return newCreatives;
        });
    }

    async function handleCreativesLoad(rowId) {
        tableActions.setLoading(true);
        try {
            await loadCreatives(rowId);
        } finally {
            if (isMounted()) {
                tableActions.setLoading(false);
            }
        }
    }

    function handleCreativesUnloadAll() {
        setCreatives(null);
    }

    async function handleCreativesLoadAll() {
        tableActions.setLoading(true);
        try {
            await loadCreatives();
        } finally {
            if (isMounted()) {
                tableActions.setLoading(false);
            }
        }
    }

    creativeColumn.getCellProps = ({ row }) => {
        if (undefined === row._creatives) {
            return {};
        }
        return { align: 'center' };
    };

    creativeColumn.renderValue = ({ rowId, row, formattedValue }) => {
        if (undefined === row._creatives) {
            return formattedValue;
        }

        return null !== row._creatives ? (
            <IconButton onClick={() => handleCreativesUnload(rowId)}>
                <Badge badgeContent={row._creatives.length} color="info" showZero>
                    <ImageIcon />
                </Badge>
            </IconButton>
        ) : (
            <IconButton onClick={() => handleCreativesLoad(rowId)}>
                <ImageIcon />
            </IconButton>
        );
    };

    function handleOnFilter(filterData) {
        tableActions.applyFilterData(filterData);
        tableActions.setFilterOpen(false);
    }

    function renderRowButtons({ rowId }) {
        const toggle = () => setToggleFacts((toggles) => ({ ...toggles, [rowId]: !toggles[rowId] }));

        return (
            <Tooltip title="Показать детализацию фактов">
                <IconButton onClick={toggle}>
                    {!toggleFacts[rowId] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
            </Tooltip>
        );
    }

    function renderAfterRow({ columns, row, rowId }) {
        return (
            <DetailRows
                rowId={rowId}
                row={row}
                columns={columns}
                filterData={tableProps.filterData}
                showFacts={toggleFacts[rowId]}
            />
        );
    }

    function renderAfterRows({ columns }) {
        return <TotalRow data={dataTotals} columns={columns} />;
    }

    function downloadData() {
        const csv = [];

        dataRows.forEach((data) => {
            csv.push(escapeCsv(columns.map((column) => data[column.field])));
            if (data._creatives) {
                data._creatives.forEach((data) => {
                    csv.push(escapeCsv(columns.map((column) => data[column.field])));
                });
            }
        });

        csv.unshift(escapeCsv(columns.map((column) => column.title ?? column.field)));

        downloadCsv('reports_data_' + moment().format('YYYYMMDD_hhmm') + '.csv', csv.join('\n'));
    }

    const loadedCreativesCount = creatives
        ? Object.values(creatives).reduce((count, items) => count + items.length, 0)
        : 0;

    const dataButtonsDisabled = tableProps.loading || !dataRows || !dataRows.length;

    const creativesBtnTitle = creatives ? 'Скрыть все креативы' : 'Загрузить все креативы';
    const creativesBtnHandle = creatives ? handleCreativesUnloadAll : handleCreativesLoadAll;
    const creativesBtnIcon = creatives ? (
        <Badge badgeContent={loadedCreativesCount} color="info" showZero>
            <ImageIcon />
        </Badge>
    ) : (
        <ImageIcon />
    );

    return (
        <React.Fragment>
            <CommonDataTable
                {...tableProps}
                rowsCount={dataRows.length}
                disableCellTitleOverflow
                disableSelection
                filterContent={<FilterContent onFilter={handleOnFilter} filterData={tableProps.filterData} />}
                disableTable={!!chartsOpen}
                disablePagination={!!chartsOpen ? true : undefined}
                contentBeforeTable={
                    tableProps.loading || tableProps.rows.length ? (
                        <Collapse in={!!chartsOpen} unmountOnExit>
                            <Charts
                                instruments={dataInstrumentsTotal}
                                totals={dataTotals}
                                filterData={tableProps.filterData}
                                interval={chartsInterval}
                                onIntervalChange={setChartsInterval}
                                metric={chartsMetric}
                                onMetricChange={setChartsMetric}
                                metric1={chartsMetric1}
                                onMetric1Change={setChartsMetric1}
                                metric2={chartsMetric2}
                                onMetric2Change={setChartsMetric2}
                            />
                        </Collapse>
                    ) : null
                }
                toolbarButtons={
                    <React.Fragment>
                        <ToggleButtonGroup size="small" value={!!chartsOpen} exclusive>
                            <ToggleButton value={false} onClick={() => setChartsOpen(false)}>
                                Таблица
                            </ToggleButton>
                            <ToggleButton value={true} onClick={() => setChartsOpen(true)}>
                                Графики
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <span style={{ marginLeft: 5 }}></span>
                        <Tooltip title={creativesBtnTitle}>
                            <span>
                                <IconButton onClick={creativesBtnHandle} disabled={dataButtonsDisabled}>
                                    {creativesBtnIcon}
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Скачать в CSV">
                            <span>
                                <IconButton onClick={downloadData} color="excel" disabled={dataButtonsDisabled}>
                                    <ExcelIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </React.Fragment>
                }
                renderRowButtons={renderRowButtons}
                renderAfterRow={renderAfterRow}
                renderAfterRows={renderAfterRows}
            />
        </React.Fragment>
    );
}
