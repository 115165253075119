import React from 'react';

import { Alert, Button, FormControl } from '@mui/material';

import { useDataForm } from '../../components/common/DataForm';
import { FormDate } from '../../components/common/FormDate';
import { ClientsSelect } from '../../components/ClientsSelect';
import { BrandsSelect } from '../../components/BrandsSelect';
import { CampaignsSelect } from '../../components/CampaignsSelect';
import { InstrumentsSelect } from '../../components/InstrumentsSelect';
import { FormText } from '../../components/common/FormText';

import { useFormStyles } from '../../hooks/useFormStyles';
import { isFilterEmpty } from './utils';

export function FilterContent({ onFilter, filterData = {} }) {
    const classes = useFormStyles();

    const { data, setData, fieldProps } = useDataForm({ initialData: filterData });

    // Зачем этот изврат? При клике и переходе между страницами типа /plans/reports/134 и /plans/reports
    // этот компонент не маунтиться заново, соответственно используется 'data' из прошлого setState, игнорируя
    // текущий filterData переданный в пропы, а при смене страниц этот проп как-раз и менятся, и нам тоже
    // нужно чтобы стейты инпутов и вообще всех данных фильтра поменялись.
    React.useEffect(() => setData(filterData), [setData, filterData]);

    function handleFilter() {
        onFilter(data);
    }

    return (
        <div>
            {isFilterEmpty(data) ? <Alert severity="info">Необходимо применить какие-нибудь фильтры.</Alert> : null}
            <div className={classes.fieldsGroup}>
                <ClientsSelect {...fieldProps('plans.client_id')} />
                <BrandsSelect {...fieldProps('plans.brand_id')} clientIds={data?.plans?.client_id} />
                <CampaignsSelect {...fieldProps('plans.campaign_id')} brandIds={data?.plans?.brand_id} />
                <InstrumentsSelect {...fieldProps('instrument_id')} />
            </div>
            <div className={classes.fieldsGroup}>
                <FormDate {...fieldProps('start_on')} label="Начало кампании" />
                <FormDate {...fieldProps('finish_on')} label="Окончание кампании" />
                <FormText {...fieldProps('iid')} label="iid" />
            </div>
            <div className={classes.fieldsGroup}>
                <FormDate {...fieldProps('facts.data_on_from')} label="Факты от даты" />
                <FormDate {...fieldProps('facts.data_on_to')} label="Факты до даты" />
                <FormControl>
                    <Button onClick={handleFilter}>Показать</Button>
                </FormControl>
            </div>
        </div>
    );
}
