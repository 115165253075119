import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { ruRU } from '@mui/material/locale';

import moment from 'moment';
import 'moment/locale/ru';

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import pinkColor from '@mui/material/colors/pink';
import blueColor from '@mui/material/colors/blue';
import greenColor from '@mui/material/colors/green';

import { messagesStore } from './stores/messagesStore';
import { LoginScreen } from './pages/LoginScreen/LoginScreen';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { HttpError, SuccessMessage } from './components/common/Snackbars';

const locale = 'ru';

moment.locale(locale);

const App = observer(() => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const defaultTheme = createTheme();
    const defaultTypography = defaultTheme.typography;

    const theme = createTheme(
        {
            palette: {
                primary: blueColor,
                secondary: pinkColor,
                excel: defaultTheme.palette.augmentColor({ color: { main: greenColor[700] } }),
                mode: prefersDarkMode && !(process.env.NODE_ENV === 'development') ? 'dark' : 'light',
            },
            components: {
                MuiFormControl: {
                    defaultProps: { size: 'small', margin: 'dense' },
                },
                MuiButton: {
                    defaultProps: { variant: 'contained' },
                },
                MuiTable: {
                    defaultProps: { size: 'small' },
                },
                MuiTableCell: {
                    styleOverrides: {
                        head: { fontWeight: defaultTypography.fontWeightBold },
                        sizeSmall: { paddingLeft: 3, paddingRight: 6, paddingTop: 1, paddingBottom: 1 },
                        root: {
                            fontSize: defaultTypography.pxToRem(defaultTypography.fontSize * 0.8),
                        },
                    },
                },
                MuiTab: {
                    styleOverrides: {
                        root: { maxWidth: 180 },
                    },
                },
            },
        },
        ruRU
    );

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment} locale={locale}>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <CssBaseline />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<LoginScreen />} />
                            <Route path="*" element={<Dashboard />} />
                        </Routes>
                    </BrowserRouter>
                    <HttpError
                        open={messagesStore.errorCode}
                        message={messagesStore.errorMessage}
                        onClose={() => messagesStore.cleanError()}
                    />
                    <SuccessMessage
                        open={messagesStore.isSuccessSaved}
                        onClose={() => messagesStore.setSuccessSaved(false)}
                    />
                </StyledEngineProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
});

ReactDOM.render(<App />, document.getElementById('root'));
