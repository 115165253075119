import React from 'react';

import { observer } from 'mobx-react-lite';

import { api } from '../../lib/api';
import { UrlMapsForm } from './Form';
import { Button, CircularProgress, Alert, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ExcelIcon } from '../../components/ExcelIcon';
import { authStore } from '../../stores/authStore';

function SaveState({ state }) {
    if (null === state) {
        return null;
    }

    return (
        <React.Fragment>
            <Box display="flex" mt={1} mb={1}>
                <Box m={1}>
                    <Alert>Добавлено: {state.addedCount}</Alert>
                </Box>
                <Box m={1}>
                    <Alert severity="warning">Дубликатов: {state.duplicatesCount}</Alert>
                </Box>
                <Box m={1}>
                    <Alert severity="error">Ошибок: {state.errorsCount}</Alert>
                </Box>
            </Box>
            {state.errorsMessages.length ? (
                <Box mt={1} mb={1}>
                    {state.errorsMessages.map((err, i) => (
                        <div key={i}>
                            <div>{err.url}</div>
                            <div style={{ color: 'red' }}>{err.message}</div>
                        </div>
                    ))}
                </Box>
            ) : null}
        </React.Fragment>
    );
}

export const UrlMapsList = observer(function () {
    const [count, setCount] = React.useState(null);
    const [openCreate, setOpenCreate] = React.useState(false);
    const [saveState, setSaveState] = React.useState(null);

    const reload = React.useCallback(() => {
        setCount(null);
        api.urlMapsCount().then((data) => setCount(data?.count ?? 0));
    }, []);

    React.useEffect(reload, [reload]);

    function handleCreate() {
        setOpenCreate(true);
    }

    return (
        <React.Fragment>
            {openCreate ? (
                <UrlMapsForm
                    onSave={(data) => {
                        setSaveState(data);
                        setOpenCreate(false);
                        reload();
                    }}
                    onClose={() => setOpenCreate(false)}
                />
            ) : null}

            {authStore.has('url_maps.edit') && (
                <Button onClick={handleCreate} startIcon={<AddCircleIcon />}>
                    Добавить список URL
                </Button>
            )}

            <SaveState state={saveState} />

            <p>
                <b>Всего URL в базе:</b> {null === count ? <CircularProgress size={10} /> : count}
            </p>

            <Button href="/data/url-maps.csv" variant="text" startIcon={<ExcelIcon />} size="small">
                Скачать список целиком
            </Button>
        </React.Fragment>
    );
});
