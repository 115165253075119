import React from 'react';

import moment from 'moment';
import clsx from 'clsx';

import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    Collapse,
    useTheme,
    Menu,
    CircularProgress,
    ListItemText,
    ListItemButton,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import { DeleteError } from './Snackbars';
import { ConfirmDialog } from '../ConfirmDialog';

import { PhotoWithPreview } from '../PhotoWithPreview';

import { useDataTable, encodeJsonToURIComponent, decodeJsonFromURIComponent } from './DataTable/useDataTable';

const useToolbarStyles = makeStyles((theme) => ({
    toolbarButtons: {
        flex: '1 1 100%',
    },
    toolbarSettings: {
        display: 'flex',
    },
}));

function DataTableToolbar({
    selectedRows = [],
    onColumnsToggle,
    onFilterToggle,
    onCreate,
    createButtonText,
    createButtonProps,
    onDelete,
    toolbarButtons,
    toolbarIconButtons,
}) {
    const classes = useToolbarStyles();
    const selectedCount = selectedRows.length;
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

    return (
        <div>
            <ConfirmDialog
                open={deleteConfirmation}
                title="Удаление объектов"
                text="Вы уверены что хотите удалить выбранные объекты?"
                onConfirm={() => {
                    onDelete(selectedRows);
                    setDeleteConfirmation(false);
                }}
                onClose={() => setDeleteConfirmation(false)}
            />
            <Toolbar variant="dense">
                {selectedCount > 0 ? (
                    <Typography className={classes.toolbarButtons} color="inherit" variant="subtitle1" component="div">
                        Выбрано элементов: {selectedCount}
                    </Typography>
                ) : (
                    <div className={classes.toolbarButtons}>
                        {onCreate ? (
                            <Button {...{ onClick: onCreate, startIcon: <AddCircleIcon />, ...createButtonProps }}>
                                {createButtonText}
                            </Button>
                        ) : null}
                        {toolbarButtons}
                    </div>
                )}
                {selectedCount > 0 ? (
                    <div className={classes.toolbarSettings}>
                        {onDelete ? (
                            <Tooltip title="Удалить">
                                <IconButton onClick={() => setDeleteConfirmation(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </div>
                ) : (
                    <div className={classes.toolbarSettings}>
                        {toolbarIconButtons}
                        {onColumnsToggle ? (
                            <Tooltip title="Настройка колонок">
                                <IconButton onClick={onColumnsToggle}>
                                    <ViewColumnIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        {onFilterToggle ? (
                            <Tooltip title="Фильтрация">
                                <IconButton onClick={onFilterToggle}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </div>
                )}
            </Toolbar>
        </div>
    );
}

function getValue({ column, row }) {
    return row[column.field];
}

export function formatValue({ value, column }) {
    switch (column.type) {
        case 'date':
            if (value) {
                value = moment(value).format('D MMM YYYY');
            }
            break;
        case 'datetime':
            if (value) {
                value = moment(value).format('D MMM YYYY, HH:mm:ss');
            }
            break;
        case 'integer':
            value = parseInt(value);
            if (isNaN(value)) {
                value = '';
            } else {
                value = value.toLocaleString('ru');
            }
            break;
        case 'number':
            value = parseFloat(value);
            if (isNaN(value)) {
                value = '';
            } else {
                value = value.toLocaleString('ru', {
                    minimumFractionDigits: column.precision ? column.precision : 2,
                    maximumFractionDigits: column.precision ? column.precision : 2,
                });
            }
            break;
        default:
            break;
    }

    if (value) {
        if (column.prefix) {
            if (typeof column.prefix === 'function') {
                value = column.prefix({ value, column });
            } else {
                value = column.prefix + value;
            }
        }

        if (column.suffix) {
            if (typeof column.suffix === 'function') {
                value = column.suffix({ value, column });
            } else {
                value += column.suffix;
            }
        }
    }

    return value;
}

export function renderValue({ formattedValue, column }) {
    switch (column.type) {
        case 'boolean':
            formattedValue = !!formattedValue ? <CheckIcon /> : null;
            break;
        case 'image':
            formattedValue = formattedValue ? <PhotoWithPreview url={formattedValue} /> : null;
            break;
        case 'multiline':
            if (formattedValue !== null && formattedValue.split) {
                formattedValue = formattedValue.split('\n').map((text, key) => (
                    <span key={key}>
                        {text}
                        <br />
                    </span>
                ));
            }
            break;
        default:
            break;
    }

    if (column.noWrap) {
        formattedValue = <Box sx={{ whiteSpace: 'nowrap' }}>{formattedValue}</Box>;
    }

    if (column.longContent) {
        formattedValue = (
            <Box
                sx={{
                    width: 'max-content',
                    maxWidth: 400,
                    maxHeight: '3em',
                    overflow: 'auto',
                }}
            >
                {formattedValue}
            </Box>
        );
    }

    return formattedValue;
}

export function renderCell(props) {
    const { column } = props;
    return (
        <TableCell align={column.align} {...column.getCellProps(props)}>
            {column.renderValue(props)}
        </TableCell>
    );
}

function setColumnsDefaults(columns, defaults) {
    return (columns ? columns : []).map((col) => {
        if (col.multiColumns) {
            col.multiColumns = setColumnsDefaults(col.multiColumns, defaults);
        }

        let align = 'left';
        let noWrap = false;
        let longContent = false;

        if (col.type) {
            switch (col.type) {
                case 'boolean':
                    align = 'center';
                    break;
                case 'integer':
                case 'number':
                    align = 'right';
                    noWrap = true;
                    break;
                case 'date':
                case 'datetime':
                    noWrap = true;
                    break;
                case 'multiline':
                    longContent = true;
                    break;
                default:
                    break;
            }
        }

        return {
            field: null,
            title: col.title ? col.title : col.field,
            sortable: true,
            hidden: false,
            startSortDir: 'asc',
            multiColumns: [],
            align: align,
            noWrap: noWrap,
            longContent: longContent,
            ...defaults,
            ...col,
        };
    });
}

const useTableStyles = makeStyles((theme) => ({
    paper: { position: 'relative', width: '100%' },
    inlineTable: { position: 'relative', display: 'table' },
    backdrop: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: theme.zIndex.drawer + 1,
    },
    headerCellContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '3.8em',
    },
    paginationToolbar: { minHeight: 52 },
}));

function DataTableCellTitle({ disableOverflow = false, ...props }) {
    const classes = useTableStyles();
    const ref = React.useRef();
    const [hover, setHover] = React.useState(false);

    const isOverflow = () => {
        const isOverflow = ref.current.scrollHeight - 3 > ref.current.clientHeight;
        setHover(isOverflow);
    };

    React.useEffect(() => {
        if (!disableOverflow) {
            isOverflow();
            window.addEventListener('resize', isOverflow);
            return () => {
                window.removeEventListener('resize', isOverflow);
            };
        }
    }, [disableOverflow]);

    if (disableOverflow) {
        return <div {...props} />;
    } else {
        return (
            <Tooltip title={props.children} disableHoverListener={!hover}>
                <div ref={ref} className={classes.headerCellContent} {...props} />
            </Tooltip>
        );
    }
}

function DataTableHeadCell({ column: columnProp, sortBy, sortDir, onSort, disableCellTitleOverflow }) {
    if (columnProp.hidden) {
        return null;
    }

    let multiColumns = columnProp.multiColumns.length ? columnProp.multiColumns : [columnProp];

    return (
        <TableCell align={columnProp.align} padding="normal" {...columnProp.getCellHeadProps({ column: columnProp })}>
            {multiColumns.map((column, index) => {
                let renderTitle = (
                    <DataTableCellTitle disableOverflow={disableCellTitleOverflow}>{column.title}</DataTableCellTitle>
                );

                let sortable = column.sortable && onSort;

                if (sortable) {
                    return (
                        <TableSortLabel
                            key={index}
                            active={sortBy === column.field}
                            direction={
                                sortBy !== column.field
                                    ? column.startSortDir
                                    : sortDir === null || sortDir === 'asc'
                                    ? 'asc'
                                    : 'desc'
                            }
                            onClick={() => onSort(column.field)}
                        >
                            {renderTitle}
                        </TableSortLabel>
                    );
                } else {
                    return <React.Fragment key={index}>{renderTitle}</React.Fragment>;
                }
            })}
        </TableCell>
    );
}

export function DataTableWrapper({ inlineTable, PaperProps, children }) {
    const classes = useTableStyles();
    return !inlineTable ? (
        <Paper {...{ className: clsx(classes.paper, PaperProps.className), ...PaperProps }} children={children} />
    ) : (
        <div className={classes.inlineTable} children={children} />
    );
}

export function DataTableContainer({ inlineTable, TableContainerProps, children }) {
    return !inlineTable ? <TableContainer {...TableContainerProps} children={children} /> : <div children={children} />;
}

export function DataTableCell({ column, row, rowId }) {
    if (column.hidden) {
        return null;
    }

    const value = column.getValue({ column, row, rowId });
    const formattedValue = column.formatValue({ value, column, row, rowId });

    return column.renderCell({ value, formattedValue, column, row, rowId });
}

export function DataTableCellSelection({
    rowProps = {},
    disableSelection = false,
    selected = false,
    onSelect = (rowId) => null,
}) {
    if (disableSelection) {
        return null;
    }

    function handleClick() {
        onSelect(rowProps.rowId);
    }

    return (
        <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={handleClick} size="small" />
        </TableCell>
    );
}

export function DataTableCellRowButtons({
    rowProps = {},
    disableRowButtons = false,
    selected = false,
    renderRowButtons = (rowProps) => null,
    onEdit,
}) {
    if (disableRowButtons) {
        return null;
    }

    return (
        <TableCell>
            <div style={{ display: 'flex' }}>
                {onEdit ? (
                    <Tooltip title="Редактировать">
                        <IconButton onClick={() => onEdit(rowProps.rowId, rowProps.row)} size="small">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
                {renderRowButtons(rowProps)}
            </div>
        </TableCell>
    );
}

export function DataTable({
    onCreate,
    createButtonText = 'Создать',
    createButtonProps,
    onEdit,
    onDelete,
    columns: columnsProp = [],
    columnsOpen = false,
    onColumnsChange,
    onColumnsToggle,
    rows = [],
    rowsCount = -1,
    loading = false,
    getRowId = (row) => row.id,
    getCellHeadProps = () => null,
    getCellProps = () => null,
    getValue: getValueProp = getValue,
    formatValue: formatValueProp = formatValue,
    renderValue: renderValueProp = renderValue,
    renderCell: renderCellProp = renderCell,
    onRowSelection = () => null,
    selectedRows = [],
    onSelectedRows,
    rowsPerPageOptions = [5, 10, 20, 50],
    filterContent,
    filterOpen = false,
    onFilterToggle,
    page = 0,
    onPage,
    rowsPerPage,
    onRowsPerPage,
    sortBy = null,
    onSortBy,
    sortDir = null,
    onSortDir,
    deleteError = false,
    onDeleteError,
    renderBeforeTable = () => null,
    renderAfterTable = () => null,
    renderBeforeHeader = () => null,
    renderAfterHeader = () => null,
    renderBeforeRows = () => null,
    renderAfterRows = () => null,
    renderBeforeRow = () => null,
    renderAfterRow = () => null,
    renderRowButtons = () => null,
    inlineTable = false,
    toolbarButtons,
    toolbarIconButtons,
    contentBeforeTable,
    disableSelection,
    disableTable = false,
    disablePagination,
    disableHeader = false,
    disableToolbar,
    disableCellTitleOverflow = false,
    TableContainerProps = {},
    PaperProps = {},
}) {
    const theme = useTheme();
    const classes = useTableStyles();

    if (typeof rowsPerPage === 'undefined') {
        rowsPerPage = rowsPerPageOptions[0];
    }

    if (typeof disableSelection === 'undefined') {
        disableSelection = !onSelectedRows;
    }

    if (typeof disableToolbar === 'undefined') {
        disableToolbar = !onCreate && !onDelete && !onColumnsToggle && !filterContent && !toolbarButtons;
    }

    if (typeof disablePagination === 'undefined') {
        disablePagination = !onPage && !onRowsPerPage;
    }

    const columns = setColumnsDefaults(columnsProp, {
        getCellHeadProps,
        getCellProps,
        getValue: getValueProp,
        formatValue: formatValueProp,
        renderValue: renderValueProp,
        renderCell: renderCellProp,
    });

    function isRowSelected(rowId) {
        return selectedRows.indexOf(rowId) !== -1;
    }

    function handleSelectionChange(newSelectedRows) {
        if (onSelectedRows) {
            onSelectedRows(newSelectedRows);
        }
    }

    function handleSelectAll(event) {
        if (event.target.checked) {
            handleSelectionChange(rows.map((row) => getRowId(row)));
        } else {
            handleSelectionChange([]);
        }
    }

    function handleSelectOne(rowId) {
        const rowData = rows.find((row) => getRowId(row) === rowId);
        const selectedRowIndex = selectedRows.indexOf(rowId);

        let newSelectedRows = [];

        if (selectedRowIndex === -1) {
            newSelectedRows = newSelectedRows.concat(selectedRows, rowId);
        } else if (selectedRowIndex === 0) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
        } else if (selectedRowIndex === selectedRows.length - 1) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
        } else if (selectedRowIndex > 0) {
            newSelectedRows = newSelectedRows.concat(
                selectedRows.slice(0, selectedRowIndex),
                selectedRows.slice(selectedRowIndex + 1)
            );
        }

        handleSelectionChange(newSelectedRows);

        onRowSelection({
            id: rowId,
            data: rowData,
            isSelected: selectedRowIndex === -1,
        });
    }

    function handleOnPageChange(newPage) {
        if (selectedRows.length) {
            handleSelectionChange([]);
        }

        if (onPage) {
            onPage(newPage);
        }
    }

    function handleOnRowsPerPageChange(newRowsPerPage) {
        if (selectedRows.length) {
            handleSelectionChange([]);
        }

        handleOnPageChange(0);
        if (onRowsPerPage) {
            onRowsPerPage(newRowsPerPage);
        }
    }

    function handleOnSortBy(newSortBy) {
        if (selectedRows.length) {
            handleSelectionChange([]);
        }

        if (onSortBy) {
            onSortBy(newSortBy);
        }
    }

    function handleOnSortDir(newSortDir) {
        if (selectedRows.length) {
            handleSelectionChange([]);
        }

        if (onSortDir) {
            onSortDir(newSortDir);
        }
    }

    function handleOnSort(field) {
        let fieldColumn = null;

        columnSearch: for (let col of columns) {
            if (col.field === field) {
                fieldColumn = col;
                break;
            }

            if (col.multiColumns.length) {
                for (let mcol of col.multiColumns) {
                    if (mcol.field === field) {
                        fieldColumn = mcol;
                        break columnSearch;
                    }
                }
            }
        }

        const realSortDir = sortDir === null || sortDir === 'asc' ? 'asc' : 'desc';

        let newSortBy = field;
        let newSortDir = fieldColumn.startSortDir;

        // Если мы уже сортируем по этому полю и направление начальное,
        // тогда меняем сортировку в другую сторону
        if (sortBy === field && realSortDir === fieldColumn.startSortDir) {
            newSortDir = realSortDir === 'asc' ? 'desc' : 'asc';
        }

        // Если мы уже сортируем по этому полю и сортировока уже не стартовая
        if (sortBy === field && realSortDir !== fieldColumn.startSortDir) {
            newSortBy = null;
            newSortDir = null;
        }

        if (sortBy !== newSortBy) {
            handleOnSortBy(newSortBy);
        }

        if (realSortDir !== newSortDir) {
            handleOnSortDir(newSortDir);
        }
    }

    function handleOnDeleteErrorClose() {
        if (onDeleteError) {
            onDeleteError(false);
        }
    }

    const [columnsAnchorElement, setColumnsAnchorElement] = React.useState(null);

    function handleOnColumnsToggle(event) {
        if (onColumnsToggle) {
            setColumnsAnchorElement(event.currentTarget);
            onColumnsToggle(!columnsOpen);
        }
    }

    function handleOnColumnsClose() {
        if (onColumnsToggle) {
            onColumnsToggle(false);
        }
    }

    function handleOnColumnsChange(index, visible) {
        if (onColumnsChange) {
            const newColumns = [...columnsProp];
            newColumns[index].hidden = !visible;
            onColumnsChange(newColumns);
        }
    }

    function handleOnFilterToggle() {
        if (onFilterToggle) {
            onFilterToggle(!filterOpen);
        }
    }

    const disableRowButtons = !onEdit && !renderRowButtons;

    return (
        <DataTableWrapper inlineTable={inlineTable} PaperProps={PaperProps}>
            {loading ? (
                <div className={classes.backdrop}>
                    <CircularProgress size={50} variant="indeterminate" color="primary" />
                </div>
            ) : null}
            <DeleteError open={deleteError} onClose={handleOnDeleteErrorClose} />
            {!disableToolbar ? (
                <DataTableToolbar
                    selectedRows={selectedRows}
                    onColumnsToggle={onColumnsToggle ? handleOnColumnsToggle : undefined}
                    onFilterToggle={filterContent ? handleOnFilterToggle : undefined}
                    onCreate={onCreate}
                    createButtonText={createButtonText}
                    createButtonProps={createButtonProps}
                    onDelete={onDelete}
                    toolbarButtons={toolbarButtons}
                    toolbarIconButtons={toolbarIconButtons}
                />
            ) : null}
            <Menu
                anchorEl={columnsAnchorElement}
                open={columnsOpen}
                onClose={handleOnColumnsClose}
                MenuListProps={{ dense: true, disablePadding: true }}
            >
                {columns.map((column, index) => (
                    <ListItemButton key={index} onClick={(e) => handleOnColumnsChange(index, !!column.hidden)}>
                        <Checkbox edge="start" size="small" disableRipple checked={!column.hidden} />
                        <ListItemText>{column.title}</ListItemText>
                    </ListItemButton>
                ))}
            </Menu>
            {filterContent ? (
                <Collapse in={filterOpen}>
                    <Box p={2}>{filterContent}</Box>
                </Collapse>
            ) : null}
            {contentBeforeTable}
            {!disableTable ? (
                <DataTableContainer inlineTable={inlineTable} TableContainerProps={TableContainerProps}>
                    {renderBeforeTable({ rows, columns })}
                    <Table stickyHeader={inlineTable ? false : true}>
                        {!disableHeader ? (
                            <TableHead>
                                {renderBeforeHeader({ rows, columns })}
                                <TableRow>
                                    {!disableSelection ? (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={
                                                    selectedRows.length > 0 && selectedRows.length !== rows.length
                                                }
                                                checked={selectedRows.length > 0}
                                                onChange={handleSelectAll}
                                                size="small"
                                            />
                                        </TableCell>
                                    ) : null}
                                    {!disableRowButtons ? <TableCell></TableCell> : null}
                                    {columns.map((column, index) => (
                                        <DataTableHeadCell
                                            key={index}
                                            column={column}
                                            sortBy={sortBy}
                                            sortDir={sortDir}
                                            onSort={onSortBy || onSortDir ? handleOnSort : null}
                                            disableCellTitleOverflow={disableCellTitleOverflow}
                                        />
                                    ))}
                                </TableRow>
                                {renderAfterHeader({ rows, columns })}
                            </TableHead>
                        ) : null}
                        <TableBody>
                            {renderBeforeRows({ rows, columns })}
                            {rows.length <= 0 ? (
                                <TableRow>
                                    {!disableSelection ? <TableCell></TableCell> : null}
                                    {!disableRowButtons ? <TableCell></TableCell> : null}
                                    <TableCell colSpan={columns.length}>
                                        <div style={{ padding: theme.spacing(2) }}>
                                            {loading
                                                ? 'Идёт загрузка данных...'
                                                : page > 0
                                                ? 'Данных больше нет'
                                                : 'Данных нет'}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {rows.map((row, index) => {
                                const rowId = getRowId(row);
                                const isSelected = isRowSelected(rowId);
                                const rowProps = { row, columns, rowId };
                                return (
                                    <React.Fragment key={rowId || index}>
                                        {renderBeforeRow(rowProps)}
                                        <TableRow hover selected={isSelected}>
                                            <DataTableCellSelection
                                                rowProps={rowProps}
                                                disableSelection={disableSelection}
                                                selected={isSelected}
                                                onSelect={handleSelectOne}
                                            />
                                            <DataTableCellRowButtons
                                                rowProps={rowProps}
                                                disableRowButtons={disableRowButtons}
                                                renderRowButtons={renderRowButtons}
                                                onEdit={onEdit}
                                            />
                                            {columns.map((column, index) => (
                                                <DataTableCell key={index} column={column} row={row} rowId={rowId} />
                                            ))}
                                        </TableRow>
                                        {renderAfterRow(rowProps)}
                                    </React.Fragment>
                                );
                            })}
                            {renderAfterRows({ rows, columns })}
                        </TableBody>
                    </Table>
                    {renderAfterTable({ rows, columns })}
                </DataTableContainer>
            ) : null}
            {!disablePagination ? (
                <TablePagination
                    component="div"
                    classes={{ root: classes.paginationToolbar }}
                    count={rowsCount}
                    page={page}
                    onPageChange={(e, p) => handleOnPageChange(p)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => handleOnRowsPerPageChange(parseInt(e.target.value, 10))}
                    rowsPerPageOptions={onRowsPerPage ? rowsPerPageOptions : []}
                    backIconButtonProps={{
                        size: 'small',
                    }}
                    nextIconButtonProps={{
                        size: 'small',
                        ...(rowsCount < 0 ? { disabled: rows.length < rowsPerPage || rows.length === 0 } : null),
                    }}
                    labelDisplayedRows={({ from, to, count, page }) =>
                        `${from}-${to}` + (count >= 0 ? ` из ${count}` : '')
                    }
                    labelRowsPerPage="Порциями по"
                />
            ) : null}
        </DataTableWrapper>
    );
}

export { useDataTable, encodeJsonToURIComponent, decodeJsonFromURIComponent };
