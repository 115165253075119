import { authStore } from '../stores/authStore';
import { messagesStore } from '../stores/messagesStore';

class Api {
    constructor(resources) {
        if (resources && Array.isArray(resources)) {
            let that = this;
            resources.forEach((resource) => {
                that[resource.name + 'List'] = async (params) => {
                    return await that.request(resource.path, params, 'POST');
                };

                that[resource.name + 'Delete'] = async (ids) => {
                    return await that.request(resource.path, { ids }, 'DELETE');
                };

                that[resource.name + 'Create'] = async (params) => {
                    return await that.request(resource.path + '/create', params, 'POST');
                };

                that[resource.name + 'Edit'] = async (id, params) => {
                    return await that.request(resource.path + '/edit/' + id, params, 'POST');
                };

                that[resource.name + 'Get'] = async (id) => {
                    return await that.request(resource.path + '/edit/' + id, null, 'GET');
                };
            });
        }
    }

    getUrl(action) {
        return new URL('/api/' + action, window.location.origin);
    }

    async request(action, data, method, settings = {}) {
        let requestUrl = this.getUrl(action);
        let requestMethod = method || 'POST';

        const requestParams = {
            method: requestMethod,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        if (action !== 'login' && action !== 'password-reset') {
            if (authStore.key) {
                requestParams.headers.Authorization = 'Bearer ' + authStore.key;
            }
        }

        if (data) {
            requestParams.body = JSON.stringify(data);
        }

        let errorCode = null;
        let errorMessage = undefined;

        try {
            let response = await fetch(requestUrl, requestParams);

            errorCode = response.status;

            if (errorCode === 401) {
                authStore.setUnauthorized();
                throw Error('Authorization required');
            }

            try {
                response = await response.json();
            } catch (e) {
                errorCode = 500;
                throw new Error('Что-то пошло не так, ошибка сервера. Попробуйте повторить позже.');
            }

            if (response.error) {
                throw new Error(response.error);
            }

            authStore.refreshAuthorization();

            return response;
        } catch (e) {
            errorMessage = e.message;
            console.error(`API action: ${action} (${requestMethod}) failed: ${e.message}`);
            if (settings.throwError) {
                throw e;
            }
        }

        messagesStore.setError(errorCode, errorMessage);

        return null;
    }

    async upload(fileObject, settings = {}) {
        return new Promise((resolve, reject) => {
            let action = 'upload';
            let requestMethod = 'POST';
            let requestUrl = this.getUrl(action);

            let data = new FormData();
            data.append('file', fileObject);

            let request = new XMLHttpRequest();

            request.open(requestMethod, requestUrl);

            request.setRequestHeader('Accept', 'application/json');

            if (authStore.key) {
                request.setRequestHeader('Authorization', 'Bearer ' + authStore.key);
            }

            request.upload.addEventListener('progress', (e) => {
                let percentCompleted = (e.loaded / e.total) * 100;
                if (settings.onProgress) {
                    settings.onProgress({
                        percent: percentCompleted,
                        loaded: e.loaded,
                        total: e.total,
                    });
                }
            });

            request.addEventListener('load', function (e) {
                if (request.status === 401) {
                    authStore.setUnauthorized();
                }

                try {
                    if (request.status === 413) {
                        throw new Error('Файл слишком большой для загрузки');
                    }

                    if (request.status < 100 || request.status >= 300) {
                        throw new Error('Неожиданная ошибка, попробуйте ещё раз');
                    }

                    let response = JSON.parse(request.response);

                    if (response.error) {
                        throw new Error(response.error);
                    }

                    resolve(response);
                } catch (e) {
                    console.error(`API action: ${action} (${requestMethod}) failed: ${e.message}`);

                    reject(e);
                }
            });

            try {
                request.send(data);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Функция авторизации в системе.
     * В случае успешной авторизации возвращает объект с ключём "key".
     * В случае возникновения ошибок возвращает объект с ключём "errors" содержащий
     * список ошибок в переданных полях.
     *
     * @param {string} login логин
     * @param {password} password пароль
     */
    async login(login, password) {
        return await this.request('login', { login, password });
    }

    /**
     * Функция устанавливает новый пароль для пользователя.
     *
     * Если передан только
     * логин и данный пользователь существует, ему высылается код на e-mail
     * для изменения пароля.
     *
     * Если передан и код и пароли, то пароль сбрасывается но указанный.
     *
     * При любых ошибках возвращается не пустой объект errors содержащий
     * ключи полей, в которых произошла ошибка и описание этой ошибки.
     *
     * Если ошибок не было, то для первого поведения возвращается ключ
     * codeSent: true
     *
     * Для второго случая возвращается ключ
     * passwordChanged: true
     *
     * @param {string} loginOrEmail логин или e-mail пользователя
     * @param {string} code высланный на e-mail код
     * @param {string} password новый пароль
     */
    async passwordReset(loginOrEmail, code, password) {
        return await this.request('password-reset', {
            login: loginOrEmail,
            code,
            password,
        });
    }

    async dictionaries(settings) {
        return await this.request('dictionaries', null, 'GET', settings);
    }

    async permissionsList() {
        return await this.request('dictionaries/permissions', null, 'GET');
    }

    async rolesList() {
        return await this.request('dictionaries/roles', null, 'GET');
    }

    async plansReportsFactsImprovado(planId, filterData) {
        return await this.request('plans/reports/facts-improvado/' + planId, { filterData }, 'POST');
    }

    async plansReportsFactsGa(planId, filterData) {
        return await this.request('plans/reports/facts-ga/' + planId, { filterData }, 'POST');
    }

    async plansReportsFactsSm(planId, filterData) {
        return await this.request('plans/reports/facts-sm/' + planId, { filterData }, 'POST');
    }

    async plansReportsFactsDaily(interval, filterData) {
        return await this.request('plans/reports/facts-interval', { interval, filterData }, 'POST');
    }

    async pairsPairsList(pairId) {
        return await this.request('pairs/pairs-list/' + pairId, null, 'GET');
    }

    async plansReportsCreativesAll(params) {
        return await this.request('plans/reports/creatives', params, 'POST');
    }

    async plansReportsCreatives(planId, params) {
        return await this.request('plans/reports/creatives/' + planId, params, 'POST');
    }

    async oauthCode(params, settings) {
        let q = [];
        for (let k in params) {
            q.push(encodeURIComponent(k) + '=' + encodeURIComponent(params[k]));
        }
        q = q.join('&');
        return await this.request('oauth/code' + (q ? '?' + q : ''), null, 'GET', settings);
    }

    async gaUpload(file) {
        return await this.request('ga/upload', { file }, 'POST');
    }

    async urlMapsCount() {
        return await this.request('url-maps/count', null, 'GET');
    }

    async urlMapsCreate(params) {
        return await this.request('url-maps/create', params, 'POST');
    }

    async adminAccountsTry(id) {
        return await this.request('admin/accounts/try', { id }, 'POST');
    }
}

const api = new Api([
    { name: 'adminAccounts', path: 'admin/accounts' },
    { name: 'adminRoles', path: 'admin/roles' },
    { name: 'adminLibraryClients', path: 'admin/library/clients' },
    { name: 'adminLibraryBrands', path: 'admin/library/brands' },
    { name: 'adminLibraryInstruments', path: 'admin/library/instruments' },
    { name: 'adminLibrarySubInstruments', path: 'admin/library/sub-instruments' },
    { name: 'adminLibraryAdvFormats', path: 'admin/library/adv-formats' },
    { name: 'adminLibraryDivisions', path: 'admin/library/divisions' },
    { name: 'adminLibraryPurchaseUnits', path: 'admin/library/purchase-units' },
    { name: 'adminLibraryCampaignTypes', path: 'admin/library/campaign-types' },
    { name: 'plans', path: 'plans' },
    { name: 'plansReports', path: 'plans/reports' },
    { name: 'ga', path: 'ga' },
    { name: 'pairs', path: 'pairs' },
]);

export { api };
