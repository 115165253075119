import React from 'react';

import { Button, FormControl } from '@mui/material';

import { api } from '../../../lib/api';
import { LibraryDataTable, useLibraryDataTable } from '../LibraryDataTable';
import { AdvFormatsForm } from './Form';
import { useFormStyles } from '../../../hooks/useFormStyles';
import { dictionariesStore } from '../../../stores/dictionariesStore';
import { useDataForm } from '../../../components/common/DataForm';
import { FormText } from '../../../components/common/FormText';

async function rowsLoader(params) {
    return (await api.adminLibraryAdvFormatsList({ ...params }))?.rows;
}

async function rowsDeleter(ids) {
    const deleteResult = await api.adminLibraryAdvFormatsDelete(ids);
    dictionariesStore.loadDictionaries();
    return deleteResult;
}

function FilterContent({ onFilter, initialData }) {
    const classes = useFormStyles();
    const { data, fieldProps } = useDataForm({ initialData });

    function handleFilter() {
        onFilter(data);
    }

    return (
        <div className={classes.fieldsGroup}>
            <FormText {...fieldProps('filter')} label="Поиск" />
            <FormControl>
                <Button onClick={handleFilter}>Показать</Button>
            </FormControl>
        </div>
    );
}

export function AdvFormatsList() {
    const columns = [
        { field: 'name', title: 'Название' },
        { field: 'code', title: 'Код' },
        { field: 'created_at', title: 'Дата создания', type: 'datetime' },
        { field: 'created_by_name', title: 'Кто добавил' },
    ];

    const [editId, setEditId] = React.useState(false);
    const { tableProps, tableActions } = useLibraryDataTable({ columns, rowsLoader, rowsDeleter });

    function handleFilterChange(value) {
        tableActions.applyFilterData(value);
        tableActions.setFilterOpen(false);
    }

    return (
        <React.Fragment>
            {false !== editId && (
                <AdvFormatsForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <LibraryDataTable
                {...tableProps}
                filterContent={<FilterContent onFilter={handleFilterChange} initialData={tableProps.filterData} />}
                onCreate={() => setEditId(null)}
                onEdit={(id) => setEditId(id)}
            />
        </React.Fragment>
    );
}
