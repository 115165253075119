import React from 'react';

import { FormAutocompleteMultiple } from './common/FormAutocompleteMultiple';
import { dictionariesStore } from '../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';

export const ClientsSelect = observer(({ label = 'Клиент', ...props }) => {
    const list = dictionariesStore.clients;
    return <FormAutocompleteMultiple list={list} label={label} {...props} />;
});
