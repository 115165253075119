import React from 'react';

import { api } from '../lib/api';
import { FormAutocompleteMultiple } from './common/FormAutocompleteMultiple';

export function PermissionsSelect({ label = 'Разрешения', ...props }) {
    const [list, setList] = React.useState([]);

    React.useEffect(() => {
        api.permissionsList().then((data) => setList(data));
    }, []);

    return <FormAutocompleteMultiple list={list} label={label} {...props} />;
}
