import React from 'react';

import { Alert, AlertTitle, Snackbar } from '@mui/material';

export function Message({ open, onClose = () => null, title, severity, message }) {
    function handleClose() {
        onClose();
    }

    return (
        <Snackbar open={!!open} autoHideDuration={8000} onClose={handleClose}>
            <Alert variant="filled" onClose={handleClose} severity={severity}>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
}

export function DeleteError(props) {
    return (
        <Message
            title="Ошибка!"
            message="Не удалось удалить некоторые элементы, вероятно другие объекты ссылаются на них."
            severity="error"
            {...props}
        />
    );
}

export function HttpError(props) {
    return <Message title="Ошибка!" severity="error" {...props} />;
}

export function SuccessMessage(props) {
    return <Message title="Успех!" message="Данные успешно сохранены." severity="success" {...props} />;
}
