import React from 'react';

import { DataTable, useDataTable } from '../../components/common/DataTable';

export function LibraryDataTable({ ...props }) {
    return <DataTable TableContainerProps={{ style: { height: 'calc(100vh - 297px)' } }} {...props} />;
}

export function useLibraryDataTable(customConfig = {}) {
    return useDataTable({ rowsPerPage: 20, ...customConfig });
}
