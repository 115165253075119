import React from 'react';

import moment from 'moment';

import { Skeleton, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { CommonDataTable } from '../../components/CustomDataTable';
import { api } from '../../lib/api';

export function FactsImprovado({ planId, filterData }) {
    const theme = useTheme();
    const [loading, setLoading] = React.useState(true);
    const [dataRows, setDataRows] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const filterDataFacts = JSON.stringify(filterData?.facts);

    React.useEffect(() => {
        let discard = false;

        setLoading(true);

        const data = filterDataFacts ? JSON.parse(filterDataFacts) : null;
        api.plansReportsFactsImprovado(planId, data).then((data) => {
            if (!discard) {
                setLoading(false);
            }
            if (!discard && data) {
                setDataRows(data.rows);
            }
        });

        return () => (discard = true);
    }, [planId, filterDataFacts]);

    const columns = [
        { field: 'data_on', title: 'Дата', type: 'date' },
        {
            field: 'utm',
            title: 'Объявление',
            renderValue: ({ formattedValue }) => <div style={{ wordBreak: 'break-all' }}>{formattedValue}</div>,
        },
        { field: 'spent', title: 'Стоимость без НДС, ₽', type: 'number' },
        { field: 'impressions', title: 'Показы', type: 'integer' },
        { field: 'clicks', title: 'Клики', type: 'integer' },
        { field: 'video_100', title: 'Досмотры', type: 'integer' },
    ];

    const rowsCount = null === dataRows ? 0 : dataRows.length;
    const rows = null === dataRows ? [] : dataRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    let chartRows = new Map();

    if (null !== dataRows) {
        dataRows.forEach((data) => {
            const dateTitle = moment(data.data_on).format('D MMM');

            let agg = {
                Дата: dateTitle,
                Показы: 0,
            };

            if (chartRows.has(data.data_on)) {
                agg = chartRows.get(data.data_on);
            } else {
                chartRows.set(data.data_on, agg);
            }

            if (!isNaN(data.impressions)) {
                agg['Показы'] += data.impressions;
            }
        });
    }

    chartRows = Array.from(chartRows.values());

    if (loading) {
        return (
            <Skeleton
                variant="rectangular"
                animation="wave"
                width="calc(100vw - 500px)"
                height={350}
                sx={{ borderRadius: 10 }}
            />
        );
    }

    return (
        <div>
            {chartRows.length ? (
                <div style={{ width: 'calc(100vw - 500px)' }}>
                    <ResponsiveContainer width="100%" height={350}>
                        <BarChart data={chartRows}>
                            <XAxis dataKey="Дата" />
                            <Bar dataKey="Показы" fill={theme.palette.secondary.main} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : null}
            <CommonDataTable
                columns={columns}
                rows={rows}
                rowsCount={rowsCount}
                rowsPerPage={rowsPerPage}
                onRowsPerPage={setRowsPerPage}
                page={page}
                onPage={setPage}
                inlineTable
                maximizeWidth={false}
            />
        </div>
    );
}
