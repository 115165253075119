import React from 'react';

import { FormAutocomplete } from './common/FormAutocomplete';
import { dictionariesStore } from '../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';

export const ClientSelect = observer(({ label = 'Клиент', ...props }) => {
    const list = dictionariesStore.clients;
    return <FormAutocomplete list={list} label={label} {...props} />;
});
