import { makeAutoObservable } from 'mobx';

class MessagesStore {
    constructor() {
        this.errorCode = 0;
        this.errorMessage = undefined;
        this.isSuccessSaved = false;
        makeAutoObservable(this);
    }

    setError(code, message = undefined) {
        this.errorCode = code;
        this.errorMessage = message;
    }

    cleanError() {
        this.errorCode = 0;
        this.errorMessage = undefined;
    }

    setSuccessSaved(isShow) {
        this.isSuccessSaved = isShow;
    }
}

const messagesStore = new MessagesStore();

export { messagesStore };
