import { makeStyles } from '@mui/styles';
import { blue, green, grey, orange, pink, purple } from '@mui/material/colors';

export const useFormStyles = makeStyles((theme) => ({
    fieldsGroup: {
        display: 'flex',
        flexFlow: 'wrap',
        '& > *': { marginRight: theme.spacing(1) },
        '& > *:last-child': { marginRight: 0 },
    },
    tableDetailsRow: {
        paddingBottom: 0,
        paddingTop: 0,
        borderBottom: 'unset',
    },

    cellHeadVideo: { backgroundColor: theme.palette.mode === 'dark' ? pink[800] : pink[200] },
    cellHeadGa: { backgroundColor: theme.palette.mode === 'dark' ? orange[800] : orange[100] },
    cellHeadApp: { backgroundColor: theme.palette.mode === 'dark' ? purple[800] : purple[100] },

    cellPlan: { backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[200] },
    cellFact: { backgroundColor: theme.palette.mode === 'dark' ? blue[800] : blue[100] },
    cellPercent: { backgroundColor: theme.palette.mode === 'dark' ? green[800] : green[100] },
}));
