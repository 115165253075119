import React from 'react';

import { Box, CircularProgress, useTheme } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { FormFile } from '../../components/common/FormFile';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { Validator } from '../../lib/utils';
import { messagesStore } from '../../stores/messagesStore';

function GaFilesForm({ objectId, onClose = () => null, onSave = () => null }) {
    const theme = useTheme();
    const isMounted = useMountedState();

    const [loading, setLoading] = React.useState(false);

    const [progress, setProgress] = React.useState([]);

    const { data, setData, setErrors, setProcessing, fieldProps, formProps } = useDataForm({
        handleChangeMiddleware,
    });

    function handleChangeMiddleware() {
        setProcessing(false);
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([{ files: 'required' }]);

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);
            setLoading(true);
            setProgress([]);

            let newProgress = [];

            (async () => {
                try {
                    for (let fileIndex = 0; fileIndex < data.files.length; fileIndex++) {
                        if (!isMounted()) {
                            break;
                        }

                        let file = data.files[fileIndex];

                        let fileProgress = {
                            name: file.name,
                            percent: 0,
                            processing: false,
                            uploaded: false,
                            processed: false,
                            error: null,
                        };

                        newProgress.push(fileProgress);
                        setProgress([...newProgress]);

                        try {
                            let uploaded = await api.upload(file, {
                                onProgress: ({ percent }) => {
                                    if (isMounted()) {
                                        newProgress[fileIndex].percent = percent;
                                        setProgress([...newProgress]);
                                    }
                                },
                            });

                            if (!isMounted()) {
                                break;
                            }

                            newProgress[fileIndex].uploaded = true;
                            newProgress[fileIndex].processing = true;
                            setProgress([...newProgress]);

                            let result = await api.gaUpload(uploaded.path);

                            if (!isMounted()) {
                                break;
                            }

                            if (!result) {
                                throw new Error('Неизвестная ошибка');
                            }

                            if (result && result.errors) {
                                throw new Error(result.errors.file);
                            }
                        } catch (e) {
                            newProgress[fileIndex].error = e.message;
                            setProgress([...newProgress]);
                        } finally {
                            newProgress[fileIndex].processing = false;
                            newProgress[fileIndex].processed = true;
                            setProgress([...newProgress]);
                        }
                    }
                } finally {
                    if (isMounted()) {
                        setProcessing(false);
                        setLoading(false);
                        setData({ files: null });
                        if (newProgress.findIndex((p) => !!p.error) === -1) {
                            messagesStore.setSuccessSaved(true);
                            onSave();
                        } else {
                            setErrors({ files: 'В загруженных файлах имеются ошибки, проверьте!' });
                        }
                    }
                }
            })();
        }
    }

    return (
        <DataForm
            {...formProps()}
            editing={!!objectId}
            createText="Загрузка файлов данных Google Analytics"
            onSave={handleSave}
            onClose={onClose}
        >
            <div style={{ textAlign: 'center' }}>
                <FormFile
                    {...fieldProps('files')}
                    accept="text/csv"
                    loading={loading}
                    multiple
                    label="Файлы данных"
                    required
                />
                {progress.length ? (
                    <div style={{ overflow: 'auto', maxHeight: 300 }}>
                        {[...progress].reverse().map((file, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {!file.processed ? (
                                        <CircularProgress
                                            size="1rem"
                                            value={file.percent}
                                            variant={file.processing ? 'indeterminate' : 'determinate'}
                                        />
                                    ) : null}
                                    {file.uploaded && !file.processed ? (
                                        <DoneIcon style={{ color: theme.palette.success.main }} />
                                    ) : null}
                                    {file.uploaded && file.processed && !file.error ? (
                                        <DoneAllIcon style={{ color: theme.palette.success.main }} />
                                    ) : null}
                                    <Box ml={1} mr={1}>
                                        {file.name}
                                    </Box>
                                    {file.error ? (
                                        <div style={{ color: theme.palette.error.main }}>{file.error}</div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </DataForm>
    );
}

export { GaFilesForm };
