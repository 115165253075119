import React from 'react';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { messagesStore } from '../../stores/messagesStore';
import { FormFile } from '../../components/common/FormFile';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { Validator } from '../../lib/utils';

export function PairsForm({ objectId, onClose = () => null, onSave = () => null }) {
    const isMounted = useMountedState();

    const { data, setData, setErrors, setProcessing, fieldProps, formProps } = useDataForm({ handleChangeMiddleware });

    function handleChangeMiddleware() {
        setProcessing(false);
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([{ file: 'required' }]);

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);

            (async () => {
                try {
                    let file = await api.upload(data.file);

                    let result = await api.pairsCreate({
                        file: file.path,
                    });

                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                                setData({ file: null });
                            } else {
                                messagesStore.setSuccessSaved(true);
                                onSave();
                            }
                        }
                    }
                } catch (e) {
                    if (isMounted()) {
                        setErrors({ file: e.message });
                    }
                } finally {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                }
            })();
        }
    }

    return (
        <DataForm
            {...formProps()}
            editing={!!objectId}
            createText="Генерация новых пар"
            onSave={handleSave}
            onClose={onClose}
        >
            <div style={{ textAlign: 'center' }}>
                <FormFile {...fieldProps('file')} label="Файл со списком сотрудников" required />
            </div>
        </DataForm>
    );
}
