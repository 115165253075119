import React from 'react';

import { Box, Tabs, Tab, Button } from '@mui/material';

import { Link } from 'react-router-dom';

import { ClientsList } from './Clients/List';
import { BrandsList } from './Brands/List';
import { InstrumentsList } from './Instruments/List';
import { SubInstrumentsList } from './SubInstruments/List';
import { AdvFormatsList } from './AdvFormats/List';
import { DivisionsList } from './Divisions/List';
import { PurchaseUnitsList } from './PurchaseUnits/List';
import { CampaignTypesList } from './CampaignTypes/List';
import { ExcelIcon } from '../../components/ExcelIcon';

export const libraryTabs = [
    { name: 'Клиенты', api: 'clients', element: <ClientsList /> },
    { name: 'Бренды', api: 'brands', element: <BrandsList /> },
    { name: 'Инструменты', api: 'instruments', element: <InstrumentsList /> },
    { name: 'Подтипы инструментов', api: 'sub-instruments', element: <SubInstrumentsList /> },
    { name: 'Форматы внутри инструмента', api: 'adv-formats', element: <AdvFormatsList /> },
    { name: 'Деления запуска', api: 'divisions', element: <DivisionsList /> },
    { name: 'Единицы закупки', api: 'purchase-units', element: <PurchaseUnitsList /> },
    { name: 'Типы кампаний', api: 'campaign-types', element: <CampaignTypesList /> },
];

function LinkTab(props) {
    return <Tab component={Link} to={'/admin/library/' + props.value} {...props} />;
}

export function AdminLibrary({ tab }) {
    return (
        <div>
            <Button href="/data/library.xlsx" variant="text" startIcon={<ExcelIcon />} size="small">
                Постоянный адрес библиотеки
            </Button>
            <Tabs value={tab} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                {libraryTabs.map((tab) => (
                    <LinkTab key={tab.name} label={tab.name} value={tab.api} wrapped />
                ))}
            </Tabs>
            <Box mt={1}>{libraryTabs.find((tabItem) => tabItem.api === tab)?.element}</Box>
        </div>
    );
}
