import React from 'react';

import { api } from '../../lib/api';
import { useDataTable } from '../../components/common/DataTable';
import { CommonDataTable } from '../../components/CustomDataTable';
import { GaFilesForm } from './Form';
import { DownloadFileLink } from '../../components/DownloadFileLink';

async function rowsLoader(params) {
    return (await api.gaList({ ...params }))?.rows;
}

async function rowsDeleter(ids) {
    return await api.gaDelete(ids);
}

function GaFilesList() {
    const [editId, setEditId] = React.useState(false);

    const columns = [
        {
            field: 'file',
            title: 'Исходный файл',
            renderValue: ({ row }) => <DownloadFileLink name={row.file_name} url={row.file_url} size={row.file_size} />,
        },
        { field: 'created_at', title: 'Дата загрузки', type: 'datetime' },
        { field: 'created_by_name', title: 'Кто загрузил' },
    ];

    const { tableProps, tableActions } = useDataTable({ columns, rowsLoader, rowsDeleter });

    return (
        <React.Fragment>
            {false !== editId && (
                <GaFilesForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CommonDataTable {...tableProps} onCreate={() => setEditId(null)} createButtonText="Загрузка данных" />
        </React.Fragment>
    );
}

export { GaFilesList };
