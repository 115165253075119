import React from 'react';

import { useFormStyles } from '../../hooks/useFormStyles';
import { ValueWithIndicator } from './ValueWithIndicator';

const baseColumns = [
    { field: 'iid', title: 'iid' },
    { field: 'start_on', title: 'Старт', type: 'date' },
    { field: 'finish_on', title: 'Завершение', type: 'date' },
    { field: 'plan_client_name', title: 'Клиент' },
    { field: 'plan_brand_name', title: 'Бренд' },
    { field: 'plan_campaign_name', title: 'Кампания' },
    { field: 'instrument_name', title: 'Инструмент' },
    { field: 'sub_instrument_name', title: 'Подтип' },
    { field: 'adv_format_name', title: 'Формат' },
    { field: 'creative', title: 'Креативы' },
    { field: 'imp_impressions', title: 'Показы', type: 'integer' },
    { field: 'fc_impressions', title: 'Показы / прогноз', type: 'integer' },
    { field: 'percent_impressions', title: '% плана показов', type: 'number', precision: 1, suffix: '%' },
    { field: 'cpm', title: 'CPM', type: 'number', suffix: '₽' },
    { field: 'fc_cpm', title: 'План CPM', type: 'number', suffix: '₽' },
    { field: 'imp_clicks', title: 'Клики', type: 'integer' },
    { field: 'fc_clicks', title: 'Клики / прогноз', type: 'integer' },
    { field: 'percent_clicks', title: '% плана кликов', type: 'number', precision: 1, suffix: '%' },
    { field: 'cpc', title: 'CPC', type: 'number', suffix: '₽' },
    { field: 'fc_cpc', title: 'План CPC', type: 'number', suffix: '₽' },
    { field: 'ctr', title: 'CTR %', type: 'number', suffix: '%' },
    { field: 'fc_ctr', title: 'CTR % / прогноз', type: 'number', suffix: '%' },
    { field: 'total_cost', title: 'Стоимость без НДС, ₽', type: 'number', suffix: '₽' },
    { field: 'fc_cost', title: 'Стоимость без НДС, ₽ / прогноз', type: 'number', suffix: '₽' },
    { field: 'percent_cost', title: '% плана бюджета', type: 'number', precision: 1, suffix: '%' },
    { field: 'imp_r_reach', title: 'Охват', type: 'integer' },
    { field: 'fc_reach', title: 'Охват / прогноз', type: 'integer' },

    /* Данные по видео - НАЧАЛО */
    { field: 'imp_video_views', title: 'Просмотры', type: 'integer' },
    { field: 'imp_video_100', title: 'Досмотры', type: 'integer' },
    { field: 'fc_video_100', title: 'Досмотры / прогноз', type: 'integer' },
    { field: 'vtr', title: 'VTR %', type: 'number', suffix: '%' },
    { field: 'fc_vtr', title: 'VTR % / прогноз', type: 'number', suffix: '%' },
    { field: 'cpv', title: 'CPV, ₽', type: 'number', suffix: '₽' },
    { field: 'fc_cpv', title: 'CPV, ₽ / прогноз', type: 'number', suffix: '₽' },
    /* Данные по видео - КОНЕЦ */

    /* Данные по Google Analytics - НАЧАЛО */
    { field: 'ga_sessions', title: 'Сеансы web', type: 'integer' },
    { field: 'percent_ar', title: 'AR %', type: 'number', precision: 1, suffix: '%' },
    { field: 'ga_percent_refusals', title: '% отказов', type: 'number', precision: 1, suffix: '%' },
    { field: 'ga_views', title: 'Просмотры web', type: 'integer' },
    { field: 'ga_duration_avg', title: 'Длительность сеанса (сек)', type: 'integer' },
    { field: 'ga_views_depth', title: 'Глубина просмотра', type: 'number' },
    { field: 'cr', title: 'CR %', type: 'number', suffix: '%' },
    { field: 'fc_cr', title: 'CR % / прогноз', type: 'number', suffix: '%' },
    { field: 'ga_purchases', title: 'Заказы', type: 'integer' },
    { field: 'fc_purchases', title: 'Заказы / прогноз', type: 'integer' },
    { field: 'percent_purchases', title: '% плана заказов', type: 'number', precision: 1, suffix: '%' },
    { field: 'ga_income', title: 'Доход web', type: 'integer' },
    /* Данные по Google Analytics - КОНЕЦ */

    /* Данные по AppFlyer - НАЧАЛО */
    { field: 'app_registrations', title: 'Регистрации app', type: 'integer' },
    { field: 'app_installs', title: 'Установки app', type: 'integer' },
    { field: 'cpi', title: 'CPI', type: 'number', suffix: '₽' },
    { field: 'app_install_auths', title: 'Установки с авторизацией', type: 'integer' },
    { field: 'app_auth_sessions', title: 'Сеанс с авторизацией', type: 'integer' },
    { field: 'app_sessions', title: 'Сеансы app', type: 'integer' },
    { field: 'app_orders', title: 'Заказы app', type: 'integer' },
    { field: 'app_order_revenues', title: 'Доход app', type: 'number', precision: 1, suffix: '₽' },
    { field: 'total_order_revenues', title: 'Доход app+web', type: 'number', precision: 1 },
    { field: 'total_avg_check', title: 'Средний чек', type: 'number', suffix: '₽' },
    { field: 'total_percent_roi', title: 'ROI app+web, %', type: 'number', suffix: '%' },
    { field: 'cpo', title: 'CPO app+web', type: 'number', suffix: '₽' },
    { field: 'drr', title: 'ДРР app+web', type: 'number', suffix: '%' },
    /* Данные по AppFlyer - КОНЕЦ */
];

const columnsMap = new Map();
baseColumns.forEach((field) => columnsMap.set(field.field, field));

export function getColumns() {
    return baseColumns;
}

export function getColumn(field) {
    return columnsMap.get(field);
}

function createColumn(field, params) {
    return { ...getColumn(field), ...params };
}

function createRenderPercent(todayPlan, fact) {
    return ({ formattedValue, row }) => (
        <ValueWithIndicator value={formattedValue} plan={row[todayPlan]} fact={row[fact]} />
    );
}

export function useColumns() {
    const classes = useFormStyles();

    const cellHeadPropsVideo = () => ({ className: classes.cellHeadVideo });
    const cellHeadPropsGa = () => ({ className: classes.cellHeadGa });
    const cellHeadPropsApp = () => ({ className: classes.cellHeadApp });

    const cellPropsPlan = () => ({ className: classes.cellPlan });
    const cellPropsFact = () => ({ className: classes.cellFact });
    const cellPropsPercent = () => ({ className: classes.cellPercent });

    const columns = [
        createColumn('iid', { asTotal: true }),
        createColumn('start_on', { type: 'date', asTotal: true }),
        createColumn('finish_on', { type: 'date', asTotal: true }),
        createColumn('plan_client_name', { longContent: true, asTotal: true }),
        createColumn('plan_brand_name', { longContent: true, asTotal: true }),
        createColumn('plan_campaign_name', { longContent: true, asTotal: true }),
        createColumn('instrument_name', { longContent: true, asTotal: true }),
        createColumn('sub_instrument_name', { longContent: true, asTotal: true }),
        createColumn('adv_format_name', { longContent: true, asTotal: true }),
        createColumn('creative', { sortable: false, asTotal: true }),
        createColumn('imp_impressions', { getCellProps: cellPropsFact }),
        createColumn('fc_impressions', { getCellProps: cellPropsPlan }),
        createColumn('percent_impressions', {
            getCellProps: cellPropsPercent,
            renderValue: createRenderPercent('fc_today_impressions', 'imp_impressions'),
        }),
        createColumn('cpm', { getCellProps: cellPropsFact }),
        createColumn('fc_cpm', { getCellProps: cellPropsPlan }),
        createColumn('imp_clicks', { getCellProps: cellPropsFact }),
        createColumn('fc_clicks', { getCellProps: cellPropsPlan }),
        createColumn('percent_clicks', {
            getCellProps: cellPropsPercent,
            renderValue: createRenderPercent('fc_today_clicks', 'imp_clicks'),
        }),
        createColumn('cpc', { getCellProps: cellPropsFact }),
        createColumn('fc_cpc', { getCellProps: cellPropsPlan }),
        createColumn('ctr', { getCellProps: cellPropsFact }),
        createColumn('fc_ctr', { getCellProps: cellPropsPlan }),
        createColumn('total_cost', { getCellProps: cellPropsFact }),
        createColumn('fc_cost', { getCellProps: cellPropsPlan }),
        createColumn('percent_cost', {
            getCellProps: cellPropsPercent,
            renderValue: createRenderPercent('fc_today_cost', 'total_cost'),
        }),
        createColumn('imp_r_reach', { getCellProps: cellPropsFact }),
        createColumn('fc_reach', { getCellProps: cellPropsPlan }),

        /* Данные по видео - НАЧАЛО */
        createColumn('imp_video_views', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsVideo }),
        createColumn('imp_video_100', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsVideo }),
        createColumn('fc_video_100', { getCellProps: cellPropsPlan, getCellHeadProps: cellHeadPropsVideo }),
        createColumn('vtr', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsVideo }),
        createColumn('fc_vtr', { getCellProps: cellPropsPlan, getCellHeadProps: cellHeadPropsVideo }),
        createColumn('cpv', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsVideo }),
        createColumn('fc_cpv', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsVideo }),
        /* Данные по видео - КОНЕЦ */

        /* Данные по Google Analytics - НАЧАЛО */
        createColumn('ga_sessions', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsGa }),
        createColumn('percent_ar', { getCellProps: cellPropsPercent, getCellHeadProps: cellHeadPropsGa }),
        createColumn('ga_percent_refusals', { getCellProps: cellPropsPercent, getCellHeadProps: cellHeadPropsGa }),
        createColumn('ga_views', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsGa }),
        createColumn('ga_duration_avg', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsGa }),
        createColumn('ga_views_depth', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsGa }),
        createColumn('cr', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsGa }),
        createColumn('fc_cr', { getCellProps: cellPropsPlan, getCellHeadProps: cellHeadPropsGa }),
        createColumn('ga_purchases', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsGa }),
        createColumn('fc_purchases', { getCellProps: cellPropsPlan, getCellHeadProps: cellHeadPropsGa }),
        createColumn('percent_purchases', {
            getCellProps: cellPropsPercent,
            getCellHeadProps: cellHeadPropsGa,
            renderValue: createRenderPercent('fc_today_purchases', 'ga_purchases'),
        }),
        createColumn('ga_income', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsGa }),
        /* Данные по Google Analytics - КОНЕЦ */

        /* Данные по AppFlyer - НАЧАЛО */
        createColumn('app_registrations', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('app_installs', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('cpi', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('app_auth_sessions', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('app_sessions', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('app_orders', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('app_order_revenues', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('total_order_revenues', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('total_avg_check', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('total_percent_roi', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('cpo', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        createColumn('drr', { getCellProps: cellPropsFact, getCellHeadProps: cellHeadPropsApp }),
        /* Данные по AppFlyer - КОНЕЦ */
    ];

    return { columns };
}
