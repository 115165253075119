export function isFilterEmpty(data) {
    return (
        !data?.plans?.id &&
        !data?.plans?.client_id?.length &&
        !data?.plans?.brand_id?.length &&
        !data?.plans?.campaign_id?.length &&
        !data.start_on &&
        !data.finish_on &&
        !data.iid
    );
}
