import React from 'react';

import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';

export function ValueWithIndicator({ value, plan, fact, greaterFactIsBad = false }) {
    if (!value) {
        return null;
    }

    fact = +fact;
    plan = +plan;

    if (isNaN(fact) || isNaN(plan)) {
        return value;
    }

    return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <span>{value}</span>
            <span>
                {+fact > +plan ? (
                    <ArrowUpwardRoundedIcon style={{ color: greaterFactIsBad ? 'green' : 'red' }} fontSize="small" />
                ) : (
                    <ArrowDownwardRoundedIcon style={{ color: greaterFactIsBad ? 'red' : 'green' }} fontSize="small" />
                )}
            </span>
        </span>
    );
}
