import React from 'react';

import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import moment from 'moment';

import { CommonDataTable } from '../../components/CustomDataTable';
import { api } from '../../lib/api';
import { blue, green, red } from '@mui/material/colors';
import { Skeleton } from '@mui/material';

export function FactsGa({ planId, filterData }) {
    const [loading, setLoading] = React.useState(true);
    const [dataRows, setDataRows] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const filterDataFacts = JSON.stringify(filterData?.facts);

    React.useEffect(() => {
        let discard = false;

        setLoading(true);

        const data = filterDataFacts ? JSON.parse(filterDataFacts) : null;
        api.plansReportsFactsGa(planId, data).then((data) => {
            if (!discard) {
                setLoading(false);
            }

            if (!discard && data) {
                setDataRows(data.rows);
            }
        });

        return () => (discard = true);
    }, [planId, filterDataFacts]);

    const columns = [
        { field: 'data_on', title: 'Дата', type: 'date' },
        { field: 'domain', title: 'Домен' },
        { field: 'device', title: 'Устройство' },
        {
            field: 'url',
            title: 'Страница',
            renderValue: ({ formattedValue }) => <div style={{ wordBreak: 'break-all' }}>{formattedValue}</div>,
        },
        { field: 'sessions', title: 'Сессий', type: 'integer' },
        { field: 'refusals', title: 'Отказов', type: 'integer' },
        { field: 'views', title: 'Просмотров', type: 'integer' },
        { field: 'duration', title: 'Длительность', type: 'integer' },
        { field: 'purchases', title: 'Заказов', type: 'integer' },
        { field: 'income', title: 'Доход', type: 'integer' },
    ];

    const rowsCount = null === dataRows ? 0 : dataRows.length;
    const rows = null === dataRows ? [] : dataRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    let chartRows = new Map();

    if (null !== dataRows) {
        dataRows.forEach((data) => {
            const dateTitle = moment(data.data_on).format('D MMM');

            let agg = {
                Дата: dateTitle,
                Сессии: 0,
                Отказы: 0,
                Просмотры: 0,
            };

            if (chartRows.has(data.data_on)) {
                agg = chartRows.get(data.data_on);
            } else {
                chartRows.set(data.data_on, agg);
            }

            if (!isNaN(data.sessions)) {
                agg['Сессии'] += data.sessions;
            }

            if (!isNaN(data.refusals)) {
                agg['Отказы'] += data.refusals;
            }

            if (!isNaN(data.views)) {
                agg['Просмотры'] += data.views;
            }
        });
    }

    chartRows = Array.from(chartRows.values());

    if (loading) {
        return (
            <Skeleton
                variant="rectangular"
                animation="wave"
                width="calc(100vw - 500px)"
                height={350}
                sx={{ borderRadius: 10 }}
            />
        );
    }

    return (
        <div>
            {chartRows.length ? (
                <div style={{ width: 'calc(100vw - 500px)' }}>
                    <ResponsiveContainer width="100%" height={350}>
                        <BarChart data={chartRows}>
                            <XAxis dataKey="Дата" />
                            <Bar dataKey="Сессии" fill={blue[500]} />
                            <Bar dataKey="Просмотры" fill={green[500]} />
                            <Bar dataKey="Отказы" fill={red[500]} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : null}
            <CommonDataTable
                columns={columns}
                rows={rows}
                rowsCount={rowsCount}
                rowsPerPage={rowsPerPage}
                onRowsPerPage={setRowsPerPage}
                page={page}
                onPage={setPage}
                inlineTable
                maximizeWidth={false}
            />
        </div>
    );
}
