import React from 'react';

import { observer } from 'mobx-react-lite';

import { makeStyles } from '@mui/styles';
import {
    AppBar,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    SwipeableDrawer,
    Breadcrumbs,
    Collapse,
    Tooltip,
    Dialog,
    DialogContent,
    Hidden,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WcIcon from '@mui/icons-material/Wc';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LinkIcon from '@mui/icons-material/Link';
import BadgeIcon from '@mui/icons-material/Badge';

import { Routes, Route, useNavigate, useLocation, matchPath, Navigate, Link as RouterLink } from 'react-router-dom';

import mainmenuBg from '../../pics/menubg.jpg';
import toolbarBg from '../../pics/toolbarbg.png';
import Logo from '../../pics/logo-light.png';

import { ConfirmDialog } from '../../components/ConfirmDialog';
import { PageHeader } from '../../components/PageHeader';

import { authStore } from '../../stores/authStore';
import { dictionariesStore } from '../../stores/dictionariesStore';

import { AdminAccountsList } from '../AdminAccounts/List';
import { AdminRolesList } from '../AdminRoles/List';
import { PlansList } from '../Plans/List';
import { PlansReportsList } from '../PlansReports/List';
import { LoginDialog } from '../LoginScreen/LoginDialog';
import { PairsList } from '../Pairs/List';
import { Welcome } from '../Welcome/Welcome';
import { GaFilesList } from '../GoogleAnalytics/List';
import { UrlMapsList } from '../UrlMaps/List';
import { GaIcon } from '../../components/GaIcon';
import { AdminLibrary, libraryTabs } from '../AdminLibrary';

import { DEFAULT_ROUTE } from '../../constants/routes';

const drawerWidth = 280;
const drawerBreakpoint = 'xl';

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: 'no-repeat url(' + toolbarBg + ')',
        backgroundSize: 'cover',
        filter: theme.palette.mode === 'dark' ? 'brightness(75%)' : 'none',
        [theme.breakpoints.up(drawerBreakpoint)]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    nav: {
        [theme.breakpoints.up(drawerBreakpoint)]: {
            width: drawerWidth,
        },
    },
    main: {
        width: '100%',
        [theme.breakpoints.up(drawerBreakpoint)]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(drawerBreakpoint)]: {
            display: 'none',
        },
    },
    mainContent: {
        padding: theme.spacing(1.5),
    },
    imgLogo: {
        maxHeight: 30,
        marginRight: theme.spacing(2),
    },
    toolbarSpacer: { height: 48 },
    toolbarTitle: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'rgba(255, 255, 255, 0.8)',
        background: 'no-repeat url(' + mainmenuBg + ')',
        backgroundSize: 'cover',
    },
    breadcrumbs: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    menuItemButton: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
    },
    menuItemIcon: {
        color: 'rgba(255, 255, 255, 0.8)',
    },
    menuNestedList: {
        marginLeft: theme.spacing(2),
    },
    loginDialog: {
        zIndex: theme.zIndex.modal + 1,
    },
}));

function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
}

function CustomListItem(props) {
    const classes = useStyles();
    return <ListItem button classes={{ button: classes.menuItemButton }} {...props} />;
}

function CustomListItemIcon(props) {
    const classes = useStyles();
    return <ListItemIcon classes={{ root: classes.menuItemIcon }} {...props} />;
}

function SimpleMenuItem({ onClick, icon, title, children }) {
    return (
        <CustomListItem onClick={onClick}>
            <CustomListItemIcon>{icon}</CustomListItemIcon>
            <ListItemText primary={title} />
            {children}
        </CustomListItem>
    );
}

function CollapsedNestedList({ open = false, nested = true, children }) {
    const classes = useStyles();

    if (!nested) {
        return children;
    }

    return (
        <Collapse in={open}>
            <List className={classes.menuNestedList}>{children}</List>
        </Collapse>
    );
}

const MenuItems = observer(function ({ onClose = () => null }) {
    const classes = useStyles();
    const navigate = useNavigate();

    const [open, setOpen] = React.useState({ plans: true });

    function closeAndGo(url) {
        onClose(url);

        if (url) {
            navigate(url);
        }
    }

    function toggle(section) {
        let isOpen = open[section] ? false : true;
        setOpen({ ...open, [section]: isOpen });
    }

    function createItem(title, url, icon, children = null) {
        return (
            <SimpleMenuItem key={title + '-' + url} title={title} onClick={() => closeAndGo(url)} icon={icon}>
                {children}
            </SimpleMenuItem>
        );
    }

    const plansItems = [];

    if (authStore.has('plans.*')) {
        plansItems.push(createItem('Планирование', '/plans', <ScheduleIcon />));
        plansItems.push(createItem('Отчёты', '/plans/reports', <AssessmentIcon />));
    }

    if (authStore.has('google_analytics.*')) {
        plansItems.push(createItem('Google Analytics', '/ga-data', <GaIcon />));
    }

    if (authStore.has('url_maps.*')) {
        plansItems.push(createItem('URL-мастер', '/url-maps', <LinkIcon />));
    }

    const adminItems = [];

    if (authStore.has('accounts.*')) {
        adminItems.push(createItem('Аккаунты', '/admin/accounts', <AccountBoxIcon />));
        adminItems.push(createItem('Роли', '/admin/roles', <BadgeIcon />));
    }

    if (authStore.has('library.*')) {
        adminItems.push(createItem('Библиотека', '/admin/library/clients', <LibraryBooksIcon />));
    }

    const miscItems = [];

    if (authStore.has('pairs.*')) {
        miscItems.push(createItem('Парогенератор', '/pairs', <WcIcon />));
    }

    return (
        <div>
            <div className={classes.toolbarSpacer} />
            <List>
                {plansItems.length > 1 && (
                    <SimpleMenuItem title="План-Факт" onClick={() => toggle('plans')} icon={<AssessmentIcon />}>
                        {open['plans'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </SimpleMenuItem>
                )}
                <CollapsedNestedList open={open['plans']} nested={plansItems.length > 1}>
                    {plansItems}
                </CollapsedNestedList>
                {miscItems}
                {adminItems.length > 1 && (
                    <SimpleMenuItem title="Администрирование" onClick={() => toggle('admin')} icon={<SettingsIcon />}>
                        {open['admin'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </SimpleMenuItem>
                )}
                <CollapsedNestedList open={open['admin']} nested={adminItems.length > 1}>
                    {adminItems}
                </CollapsedNestedList>
            </List>
        </div>
    );
});

function DashboardBreadcrumbs({ routesMap }) {
    const classes = useStyles();
    const location = useLocation();
    const pathnames = location.pathname.split('/', 3).filter((x) => x);

    const breadcrumbs = [];
    for (let i = pathnames.length - 1; i >= 0; i--) {
        const to = pathnames.slice(0, i + 1).join('/');
        const route = routesMap.find((route) => matchPath(to, route.path));
        if (route) {
            let exist = breadcrumbs.findIndex((b) => b.path === route.path);
            if (exist < 0) {
                breadcrumbs.unshift({
                    path: route.path,
                    title: route.title,
                    to,
                    isLast: breadcrumbs.length === 0,
                    isDisabled: breadcrumbs.length === 0 || !route.element,
                });
            }
        }
    }

    return (
        <Breadcrumbs className={classes.breadcrumbs}>
            {breadcrumbs.map((b) => {
                return b.isDisabled ? (
                    <Typography color={b.isLast ? 'textPrimary' : 'textSecondary'} key={b.to}>
                        {b.title}
                    </Typography>
                ) : (
                    <LinkRouter color="inherit" to={b.to} key={b.to}>
                        {b.title}
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
}

export const Dashboard = observer(() => {
    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [logoutOpen, setLogoutOpen] = React.useState(false);

    const navigate = useNavigate();

    const isAuthorized = authStore.isAuthorized;

    React.useEffect(() => {
        if (isAuthorized) {
            dictionariesStore.loadDictionaries();
        }
    }, [isAuthorized]);

    function createRoute(path, title, element) {
        return { path, title, element };
    }

    const routesMap = [
        createRoute('/welcome', 'I.COM инструменты', <Welcome />),
        createRoute('/admin', 'Администрирование', null),
        createRoute('/admin/accounts', 'Аккаунты', <AdminAccountsList />),
        createRoute('/admin/roles', 'Роли', <AdminRolesList />),
        createRoute('/admin/library', 'Библиотека', null),
        createRoute('/plans/reports/:id', 'Отчёты', <PlansReportsList />),
        createRoute('/plans/reports', 'Отчёты', <PlansReportsList />),
        createRoute('/url-maps', 'URL-мастер', <UrlMapsList />),
        createRoute('/ga-data', 'Данные Google Analytics', <GaFilesList />),
        createRoute('/plans', 'Планирование', <PlansList />),
        createRoute('/pairs', 'Парогенератор', <PairsList />),
    ];

    libraryTabs.forEach((tab) =>
        routesMap.push(createRoute('/admin/library/' + tab.api, tab.name, <AdminLibrary tab={tab.api} />))
    );

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function handleLogout() {
        setLogoutOpen(true);
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.toolbarTitle}>
                        <Hidden smDown>
                            <img src={Logo} className={classes.imgLogo} alt="" />
                        </Hidden>
                        <Typography variant="body2">
                            <LinkRouter to="/welcome" color="inherit" component={<Link />}>
                                {process.env.REACT_APP_TITLE}
                            </LinkRouter>
                        </Typography>
                    </div>
                    <Tooltip title="Выйти из системы">
                        <IconButton color="inherit" onClick={handleLogout}>
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <nav className={classes.nav}>
                <SwipeableDrawer
                    sx={{ display: { xs: 'block', [drawerBreakpoint]: 'none' } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onOpen={handleDrawerToggle}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{ keepMounted: true }}
                >
                    <MenuItems onClose={() => setMobileOpen(false)} />
                </SwipeableDrawer>
                <Drawer
                    sx={{ display: { xs: 'none', [drawerBreakpoint]: 'block' } }}
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    open
                >
                    <MenuItems />
                </Drawer>
            </nav>
            <main className={classes.main}>
                <div className={classes.toolbarSpacer} />
                <div className={classes.mainContent}>
                    <DashboardBreadcrumbs routesMap={routesMap} />
                    <Routes>
                        {routesMap.map((route) => {
                            return (
                                <Route key={route.path} path={route.path} element={route.element} {...route.props} />
                            );
                        })}
                        <Route path="/" render={() => <Navigate to={DEFAULT_ROUTE} />} />
                        <Route
                            path="*"
                            element={
                                <div>
                                    <PageHeader>404 не найдена</PageHeader>
                                    <Typography variant="body1" paragraph>
                                        Извините, страница с таким адресом не найдена на сервере.
                                    </Typography>
                                </div>
                            }
                        />
                    </Routes>
                </div>
            </main>
            <ConfirmDialog
                open={logoutOpen}
                title="Выход из системы"
                text="Вы уверены что хотите выйти из системы?"
                onConfirm={() => {
                    authStore.setUnauthorized();
                    navigate('/');
                }}
                onClose={() => setLogoutOpen(false)}
            />
            <Dialog open={!isAuthorized} className={classes.loginDialog} maxWidth="xs">
                <DialogContent>
                    <LoginDialog />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
});
